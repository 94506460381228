import styled from "styled-components";
import { getColor } from "common/constants/theme";
import { Typography } from "@lunchboxinc/utensils";
import { Link, match } from "react-router-dom";
import { spacing } from "common/utils";

type MenuIconContainerProps = {
  $isActive: match<{}> | boolean;
};

type MenuItemButtonProps = {
  disabled: boolean;
  $isSettingsMenu: boolean;
  $isActive: match<{}> | boolean;
};

interface SidebarProps {
  isHoverExpanded: boolean;
  isMinimized: boolean;
  isMobile: boolean;
  isSettingsMenu: boolean;
}

export namespace S {
  export const ClassicAdminButton = styled.button`
    padding: 25px;
    text-align: left;
    color: ${getColor("teal", 700)};
    cursor: pointer;
    width: 180px;
  `;

  export const MenuArrowIcon = styled.div`
    vertical-align: middle;
    padding-right: 15px;
    font-size: 14px;
  `;

  export const MenuButtonLabel = styled.div`
    display: flex;
    align-items: center;
  `;

  export const MenuDropdownContainer = styled.h3`
    color: ${getColor("neutral", 600)};
    cursor: pointer;
    overflow: hidden;
    padding: 25px 0;
  `;

  export const MenuDropdownButton = styled(Link)`
    align-items: center;
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    width: 100%;
    border-left: 3px solid transparent;
  `;

  export const MenuIcon = styled.div`
    vertical-align: middle;
    font-size: 20px;
  `;

  export const MenuIconContainer = styled.span<MenuIconContainerProps>`
    padding-left: 25px;
    padding-right: 35px;
    width: 35px;
    ${({ $isActive }) =>
      $isActive
        ? `fill: ${getColor("teal", 700)}; `
        : ` fill:${getColor("neutral", 400)};  `}
  `;

  export const MenuItemButton = styled(Link)<MenuItemButtonProps>`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 25px 0;
    width: 100%;
    background-color: ${({ $isActive, $isSettingsMenu }) => {
      if ($isActive) {
        return $isSettingsMenu
          ? getColor("neutral", 200)
          : getColor("neutral", 100);
      }
    }};
    border-left: 3px solid transparent;
    border-left-color: ${({ $isActive }) => $isActive && getColor("teal", 700)};
    ${({ disabled }) => disabled && `cursor: default;`};
  `;

  export const SidebarContainer = styled.nav<SidebarProps>`
    z-index: 999999;
    position: sticky;
    top: 67px;
    bottom: 0;
    width: 232px;
    height: calc(100vh - 67px);
    overflow-x: hidden;
    overflow-y: auto;
    background-color: ${getColor("powder")};
    box-shadow: 2px 0 ${getColor("neutral", 200)};
    transition: width, 0.2s ease-in-out;
    background-color: ${({ isSettingsMenu }) => {
      if (isSettingsMenu) {
        return getColor("neutral", 100);
      }
    }};
    a {
      color: ${getColor("neutral", 600)};
      &:hover {
        color: ${getColor("teal", 600)};
      }
    }

    ${({ isMobile }) => {
      if (isMobile) {
        return `
          width: 232px;
          position: fixed;
          left: 0;
          transition: transform 0.3s ease;
        `;
      } else {
        return `
          align-self: stretch;
          ::-webkit-scrollbar {
            display: none;
          }
          -ms-overflow-style: none;
          scrollbar-width: none;
        `;
      }
    }};
    ${({ isHoverExpanded, isMinimized, isMobile }) => {
      if (isMinimized) {
        if (isMobile) {
          return `
            transform: translateX(-100%);
          `;
        }

        if (isHoverExpanded) {
          return `
            width: 80px;
            &:hover {
              width: 232px;
            }
          `;
        }

        return `
          width: 80px;
        `;
      }
    }};
  `;

  export const SidebarTitle = styled(Typography)`
    padding: 25px;
    color: ${getColor("neutral", 600)};
    display: flex;
    align-items: center;
  `;

  export const SettingsIcon = styled.div`
    font-size: 14px;
    margin-right: ${spacing(2)}px;
  `;

  interface SubMenuProps {
    padNumber: number;
  }

  export const SubMenuSpan = styled.span<SubMenuProps>`
    padding-left: ${({ padNumber }) => padNumber}px;
  `;
}
