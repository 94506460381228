import React from "react";
import { Formik, Field } from "formik";
import { Form, message, Col, Select, Input, Icon } from "antd";
import { Fields } from "components";
import { Button, T, Row } from "components/elements";
import { useUserContext } from "components/providers/User";
import { useAPIContext } from "components/providers/Api";
import { mapAxiosError, uuid } from "common/utils";
import { entitiesSchemas } from "common/schemas";
import { LocationSelector } from "./LocationSelector";
import css from "./entities.module.css";
import { Delete } from "@lunchboxinc/icons";

const CreateItem = ({
  drawerProps,
  groupId,
  optionId,
  fetchResults,
  loading,
}) => {
  const { locations } = useUserContext();
  const { MenuItems } = useAPIContext();

  const locationOptions = locations.map((i) => ({
    name: i.name,
    value: i.id,
  }));

  const altPriceChangePrice = ({ price, index, values, setFieldValue }) => {
    const { alternatePrices } = values;
    alternatePrices[index] = {
      ...alternatePrices[index],
      price: Number(price.target.value),
    };
    setFieldValue("alternatePrices", alternatePrices);
  };

  const altPriceChangeLocation = ({
    location,
    index,
    values,
    setFieldValue,
  }) => {
    const { alternatePrices } = values;
    alternatePrices[index] = {
      ...alternatePrices[index],
      location,
    };
    setFieldValue("alternatePrices", alternatePrices);
  };

  const altPriceChangeDelete = ({ index, values, setFieldValue }) => {
    const { alternatePrices } = values;
    alternatePrices.splice(index, 1);
    setFieldValue("alternatePrices", alternatePrices);
  };

  const altPriceAddEntry = ({ values, setFieldValue }) => {
    const { alternatePrices } = values;
    alternatePrices.push({
      price: null,
      location: "",
    });
    setFieldValue("alternatePrices", alternatePrices);
  };

  const save = async (formValues, actions) => {
    const body = {
      ...formValues,
      externalId: uuid(),
      // options: formValues.options.map((option) => option.id),
    };

    if (groupId) body.group = groupId;
    if (optionId) body.option = optionId;

    try {
      actions.setSubmitting(true);
      const data = await MenuItems.create(body);
      message.success("Successfully Saved");
      fetchResults();
    } catch (e) {
      message.error(mapAxiosError(e));
    } finally {
      actions.setSubmitting(false);
      drawerProps.close();
    }
  };

  return (
    <Formik
      initialValues={{
        name: "",
        price: null,
        alternatePrices: [],
        excludedLocations: [],
        isOrderableOnline: false,
        isDeleted: false,
      }}
      validationSchema={entitiesSchemas.itemSchema}
      onSubmit={(formValues, actions) => save(formValues, actions)}
    >
      {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
        <Form onSubmit={handleSubmit}>
          <Field
            label="Name"
            name="name"
            placeholder="Name"
            component={Fields.Input}
          />
          <Field
            type="number"
            label="Price"
            name="price"
            placeholder="0"
            addonBefore="$"
            component={Fields.Input}
          />
          <Row spacing={15}>
            <T label>Alternate Prices</T>
            {values.alternatePrices.map((price, index) => (
              <Row spacing={15}>
                <Col md={8}>
                  <T label>Price</T>
                  <Input
                    type="number"
                    label="Price"
                    name="price"
                    value={price.price}
                    placeholder="0"
                    addonBefore="$"
                    onChange={(price) =>
                      altPriceChangePrice({
                        price,
                        index,
                        values,
                        setFieldValue,
                      })
                    }
                  />
                </Col>
                <Col md={10}>
                  <T label>Location</T>
                  <Select
                    options={locationOptions}
                    value={price.location}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(location) =>
                      altPriceChangeLocation({
                        location,
                        index,
                        values,
                        setFieldValue,
                      })
                    }
                  >
                    {locationOptions.map((i, index) => (
                      <Select.Option key={i.value}>{i.name}</Select.Option>
                    ))}
                  </Select>
                </Col>
                <Col md={6} className={css.alignBottom}>
                  <Button
                    color="red"
                    size="small"
                    onClick={() =>
                      altPriceChangeDelete({
                        index,
                        values,
                        setFieldValue,
                      })
                    }
                  >
                    <Delete height={15} width={"100%"} />
                  </Button>
                </Col>
              </Row>
            ))}
            <Button onClick={() => altPriceAddEntry({ values, setFieldValue })}>
              Add
            </Button>
          </Row>
          <LocationSelector
            loading={loading}
            title="Excluded Locations"
            addLocations={(locations) =>
              setFieldValue("excludedLocations", locations)
            }
          />
          <Button
            htmlType="submit"
            size="small"
            color="blue"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Create
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export { CreateItem };
