import React, { useEffect, useState } from "react";
import moment from "moment";
import isEqual from "lodash/isEqual";
import { Card, Col, Table as AntTable, message } from "antd";
import { Tooltip } from "@lunchboxinc/utensils";
import { LoadingSpinner } from "components/v2/Loading/LoadingSpinner/LoadingSpinner";
import {
  ConditionalWrapper,
  Button,
  Titles,
  Row,
  Price,
} from "components/elements";
import { useAPIContext } from "components/providers/Api";
import { constants, utils } from "common";

import { Cards, Tables, Permissions } from "components/fragments";

import { useAnalyticsActions, useRefetchableResource } from "hooks";
import Header from "../Analytics/Header";

const { permissions } = constants;
const { downloadCsv, mapAxiosError } = utils;
const {
  routes: { STORED_VALUE_CARDS_REPORTS },
} = permissions;
const { Permission } = Permissions;
const { BlueCardTitle } = Titles;
const { StatCard, BlueCard } = Cards;
const { Table } = Tables;

const outstandingGiftCardDescription =
  "This reflects the all-time value of outstanding gift cards.";
const outstandingPreloadDescription =
  "This reflects the all-time value of outstanding preloads.";
const popoverContent =
  "Gift card reports must first be filtered before exporting a CSV";

const getInitialDateFilter = () => {
  const today = moment();
  const startDate = today.clone().startOf("month");
  const endDate = today.clone().endOf("month");
  return { startDate, endDate };
};
const isFiltersEmpty = (query) => {
  return isEqual(query.filters, getInitialDateFilter());
};

const GiftCardAnalytics = () => {
  const { GiftCards } = useAPIContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { actions, params } = useAnalyticsActions({
    initialDates: getInitialDateFilter(),
  });

  const { resource, fetching, error } = useRefetchableResource({
    path: "/stored-value-cards/reports",
    data: { filters: params },
    method: "GET",
  });

  const onExportCSV = async (query) => {
    setIsSubmitting(true);
    try {
      const { data } = await GiftCards.getGiftCardCSV({
        limit: 0,
        skip: 0,
        filters: query,
      });
      downloadCsv(data);
    } catch (e) {
      message.error(mapAxiosError(e));
    } finally {
      setIsSubmitting(false);
    }
  };

  const {
    storedValueCardReport: {
      locations,
      outstandingGiftCard,
      outstandingPreload,
      purchased,
      spent,
    },
  } = Object.entries(resource).length
    ? resource
    : { storedValueCardReport: { locations: [] } };

  useEffect(() => {
    if (error) {
      message.error(error);
    }
  }, [error]);

  return (
    <LoadingSpinner spinning={fetching}>
      <Card bordered={false}>
        <Header
          actions={actions}
          title="Gift Card Reports Breakdown"
          initialDateValues={params.createdAt}
        />
        <Row gutter={30}>
          <Col xs={24} sm={6} style={{ marginBottom: "30px" }}>
            <StatCard
              title="Outstanding Gift Card"
              type="price"
              value={outstandingGiftCard}
              description={outstandingGiftCardDescription}
              icon="mobile-total-icon"
            />
          </Col>

          <Col xs={24} sm={6} style={{ marginBottom: "30px" }}>
            <StatCard
              title="Outstanding Preload"
              type="price"
              value={outstandingPreload}
              description={outstandingPreloadDescription}
              icon="mobile-total-icon"
            />
          </Col>

          <Col xs={24} sm={6} style={{ marginBottom: "30px" }}>
            <StatCard
              title="Purchased"
              type="price"
              value={purchased}
              icon="mobile-orders-icon"
            />
          </Col>

          <Col xs={24} sm={6} style={{ marginBottom: "30px" }}>
            <StatCard
              title="Spent"
              type="price"
              value={spent}
              icon="desktop-total-icon"
            />
          </Col>
        </Row>
      </Card>

      <BlueCard>
        <Row type="flex" justify="space-between">
          <Col>
            <BlueCardTitle>Gift Card Reports</BlueCardTitle>
          </Col>
          <Col>
            <Permission
              requiredPermissions={STORED_VALUE_CARDS_REPORTS}
              yes={() => (
                <ConditionalWrapper
                  condition={isFiltersEmpty(params)}
                  wrapper={(children) => (
                    <Tooltip placement="top" popupLabel={popoverContent}>
                      {children}
                    </Tooltip>
                  )}
                >
                  <Button
                    onClick={() => {
                      if (!isFiltersEmpty(params)) {
                        onExportCSV(params);
                      }
                    }}
                    disabled={isSubmitting}
                    color="blue"
                    size="small"
                    height="40px"
                  >
                    Export CSV
                  </Button>
                </ConditionalWrapper>
              )}
            />
          </Col>
        </Row>
        <Row spacing={50}>
          <Table
            size="small"
            striped
            pagination={false}
            dataSource={locations}
            rowKey={(i, index) => index}
            loading={fetching}
          >
            <AntTable.Column title="Location" dataIndex="name" />
            <AntTable.Column
              title="Gift Card Value Spent"
              dataIndex="spent"
              render={(txt) => <Price value={txt} align="left" />}
            />
          </Table>
        </Row>
      </BlueCard>
    </LoadingSpinner>
  );
};

export default GiftCardAnalytics;
