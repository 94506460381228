import styled from "styled-components";
import { Link } from "react-router-dom";
import { getColor } from "common/constants/theme";

type IconProps = {
  $isActive: boolean;
};
export namespace S {
  export const FooterButton = styled(Link)<IconProps>`
    text-align: center;
    height: 45px;
    color: ${getColor("powder")};
    font-size: 14px;
    text-transform: uppercase;
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;
    fill: ${({ $isActive }) =>
      $isActive ? getColor("yellow", 500) : getColor("neutral", 400)};
    :hover {
      fill: ${getColor("teal", 600)};
    }
  `;

  export const Icon = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > svg {
      height: 30px;
      padding-bottom: 5px;
    }
  `;

  export const FooterWrapper = styled.div`
    background-color: ${getColor("gray", 10)};
    display: flex;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 10px;
    color: ${getColor("powder")};
    justify-content: center;
    z-index: 2;
  `;
}
