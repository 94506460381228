import * as Yup from "yup";

const pushNotificationSchema = Yup.object().shape({
  name: Yup.string().required("Campaign Name is required"),
  pushNotification: Yup.object().shape({
    title: Yup.string().required("Title is required"),
    text: Yup.string().required("Body text is required"),
  }),
  audience: Yup.string().required("Audience is required"),
  scheduleDate: Yup.date().min(
    new Date(),
    "Scheduled date cannot be in the past",
  ),
});

const pushNotificationSchemaV1 = Yup.object().shape({
  name: Yup.string().required("Campaign Name is required"),
  title: Yup.string().required("Title is required"),
  text: Yup.string().required("Body text is required"),
  scheduleDate: Yup.date().min(
    new Date(),
    "Scheduled date cannot be in the past",
  ),
});

export default {
  PushNotificationSchema: pushNotificationSchema,
  pushNotificationSchemaV1,
};
