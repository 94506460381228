import * as React from "react";
import { useUserContext } from "components/providers/User";
import { useAPIContext } from "components/providers/Api";
import { S } from "./styled";
import { Admin } from "pages/Admins/Admins.types";
import { Marketplace } from "pages/Marketplaces/Marketplaces.types";
import { RestaurantGroup } from "pages/RestaurantGroups/RestaurantGroups.types";

const findAppId = (
  arr: Marketplace[] | RestaurantGroup[] = [],
  client: string,
) => arr.findIndex((item: { appId: string }) => item.appId === client);

const RestaurantDropdown = () => {
  const user = useUserContext();

  const DropdownComponent = user.isSuperAdmin
    ? SuperAdminDropdown
    : AdminDropdown;

  return <DropdownComponent admin={user} />;
};

type AdminProps = {
  admin: Admin;
};

const SuperAdminDropdown = (props: AdminProps) => {
  const { allClients } = useAPIContext();
  return (
    <Dropdown
      {...props}
      restaurantGroups={allClients?.[1]}
      marketplaces={allClients?.[0]}
    />
  );
};

const AdminDropdown = (props: AdminProps) => {
  const {
    admin: { user },
  } = props;

  const restaurantGroups = user.restaurantGroups || [];
  const marketplaces = user.marketplaces || [];

  if (restaurantGroups.length + marketplaces.length <= 1) {
    return null;
  }

  return (
    <Dropdown
      {...props}
      restaurantGroups={restaurantGroups}
      marketplaces={marketplaces}
    />
  );
};

interface DropdownProps {
  admin: Admin;
  marketplaces: Marketplace[];
  restaurantGroups: RestaurantGroup[];
}

const Dropdown = ({
  admin,
  restaurantGroups = [],
  marketplaces = [],
}: DropdownProps) => {
  const { user, client, onChangePlatform, setRgConfiguration } = admin;
  const restaurantGroupMatch = findAppId(restaurantGroups, client);
  const marketplaceMatch = findAppId(marketplaces, client);

  let placeholder: string | null;

  React.useEffect(() => {
    if (restaurantGroupMatch !== -1) {
      setRgConfiguration(
        restaurantGroups[restaurantGroupMatch]?.configurations,
      );
    } else if (marketplaceMatch !== -1) {
      setRgConfiguration(marketplaces[marketplaceMatch]?.configurations);
    } else {
      const index = restaurantGroups.findIndex(
        (item: RestaurantGroup) => item.id === user.restaurantGroup,
      );
      setRgConfiguration(restaurantGroups[index]?.configurations ?? {});
    }
  }, []);

  if (restaurantGroupMatch !== -1) {
    placeholder = restaurantGroups[restaurantGroupMatch]?.name;
  } else if (marketplaceMatch !== -1) {
    placeholder = marketplaces[marketplaceMatch]?.name;
  } else {
    const index = restaurantGroups.findIndex(
      (item: RestaurantGroup) => item.id === user.restaurantGroup,
    );
    placeholder = restaurantGroups[index]?.name;
  }

  const rgOptions = restaurantGroups.map((e: RestaurantGroup) => ({
    label: e.name,
    value: { ...e, type: "admin" },
  }));

  const marketplaceOptions = marketplaces.map((e: Marketplace) => ({
    label: e.name,
    value: { ...e, type: "marketplace" },
  }));

  const optionsToRender =
    rgOptions?.length || marketplaceOptions?.length
      ? [...marketplaceOptions, ...rgOptions]
      : [
          {
            label: "No Client Data Found",
            value: "No Data",
          },
        ];

  return (
    <S.StyledSelectSearch
      options={optionsToRender}
      placeholder={placeholder}
      onChange={(e: RestaurantGroup | Marketplace) => {
        const { appId, configurations, type, id } = e;
        onChangePlatform(appId, type, id);
        setRgConfiguration(configurations);
      }}
    />
  );
};

export { RestaurantDropdown };
