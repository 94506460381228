import * as React from "react";
import { S } from "./styled";
import { Gear } from "@lunchboxinc/icons";
import { getColor } from "common/constants/theme";

interface SettingsButtonProps {
  handleClick: () => void;
  isActive: boolean;
}

const SettingsButton = ({ handleClick, isActive }: SettingsButtonProps) => {
  return (
    <S.SettingsLink
      role="button"
      aria-label="Show settings"
      aria-selected={isActive}
      $isActive={isActive}
      onClick={handleClick}
      to="/integrations-editor"
    >
      <S.SettingsIcon>
        <Gear />
      </S.SettingsIcon>
    </S.SettingsLink>
  );
};

export { SettingsButton };
