import { axios as defaultInstance } from "../../utils";

// Backend Main Endpoint & Microservices
const MAIN_SERVICE = "v0";
const AUTOMATION_SERVICE = "automation";
const CEP_SERVICE = "cep";
const DATA_SERVICE = "data/v1";

const APIMethods = (axiosInstance = defaultInstance) => {
  const { get, put, post, delete: destroy, patch } = axiosInstance;

  const restify = (name, keys) => {
    const routes = {
      create: (data) => post(`/${MAIN_SERVICE}/${name}`, data),
      readOne: (id, data) =>
        get(`/${MAIN_SERVICE}/${name}/${id}`, { params: data }),
      readMany: (data) => get(`/${MAIN_SERVICE}/${name}`, { params: data }),
      update: (id, data) => put(`/${MAIN_SERVICE}/${name}/${id}`, data),
      delete: (id) => destroy(`/${MAIN_SERVICE}/${name}/${id}`),
    };

    const returnRoutes = Object.assign(
      ...keys.map((key) => ({ [key]: routes[key] })),
    );

    return returnRoutes;
  };

  const Admins = {
    ...restify("admins", ["create", "readOne", "update"]),
    signIn: (data) => post(`/${MAIN_SERVICE}/admins/sign-in`, data),
    signOut: () => post(`/${MAIN_SERVICE}/admins/sign-out`),
    validate: () => get(`/${MAIN_SERVICE}/admin`),
    forgotPassword: (data) =>
      post(`/${MAIN_SERVICE}/admins/forgot-password`, data),
    verifyPincode: (data) => post(`/${MAIN_SERVICE}/pincode/verify`, data),
    createNotification: (data) =>
      post(`/${MAIN_SERVICE}/admin-notifications`, data),
    liveSearchAdmins: (data) =>
      get(`/${MAIN_SERVICE}/admin-notifications/livesearch`, { params: data }),
  };

  const Analytics = {
    getOverview: (data) =>
      get(`/${MAIN_SERVICE}/analytics/overview`, { params: data }),
    getStandardSalesCSV: (data) =>
      get(`/${MAIN_SERVICE}/analytics/lunchbox-sales/csv`, { params: data }),
    getCateringSalesCSV: (data) =>
      get(`/${MAIN_SERVICE}/analytics/lunchbox-sales-catering/csv`, {
        params: data,
      }),
    getSubscriptionCSV: (data) =>
      get(`/${MAIN_SERVICE}/subscriptions-general-report/csv`, {
        params: data,
      }),
  };

  const Audit = {
    ...restify("audits", ["readOne", "readMany"]),
  };

  const Cards = {
    ...restify("cards", ["readMany"]),
  };

  const Catering = {
    ...restify("catering", ["readOne", "update"]),
    accept: (id, data) => post(`/${MAIN_SERVICE}/catering/${id}/accept`, data),
    decline: (id) => post(`/${MAIN_SERVICE}/catering/${id}/decline`),
  };

  const CateringGroups = {
    create: (data) => post(`/${MAIN_SERVICE}/catering-groups`, data),
    getList: (data) =>
      get(`/${MAIN_SERVICE}/catering-groups`, { params: data }),
    getOne: (id) => get(`/${MAIN_SERVICE}/catering-groups/${id}`),
    update: (id, data) => put(`/${MAIN_SERVICE}/catering-groups/${id}`, data),
  };

  const CronActions = {
    ...restify("cron-actions", ["readMany", "update"]),
  };

  const DiningHours = {
    ...restify("dining-hours", ["create", "readOne", "update", "delete"]),
  };

  const HolidayHours = {
    ...restify("holiday-hours", ["create", "readOne", "update", "delete"]),
  };

  const Discounts = {
    ...restify("discounts", ["create", "readOne", "update"]),
    sync: () => post(`/${MAIN_SERVICE}/pos/discounts/all`),
    getPromotions: (id, data) =>
      get(`/${MAIN_SERVICE}/discounts/${id}/promotions`, { params: data }),
  };

  const EmailTemplates = {
    ...restify("email-templates", ["create", "readMany", "readOne", "update"]),
    render: (id) => get(`/${MAIN_SERVICE}/email-templates/${id}/render`),
    preview: (id) => post(`/${MAIN_SERVICE}/email-templates/${id}/preview`),
    publish: (id) => post(`/${MAIN_SERVICE}/email-templates/${id}/publish`),
  };

  const GiftCards = {
    ...restify("stored-value-cards", ["readMany", "readOne"]),
    getGiftCardCSV: (data) =>
      get(`/${MAIN_SERVICE}/stored-value-cards/reports/csv`, { params: data }),
  };

  const ItemAvailabilities = {
    ...restify("item-availabilities", ["create", "update", "delete"]),
  };

  const LiveSearch = {
    getList: (data, headers) =>
      get(`https://patron.${process.env.REACT_APP_DOMAIN}/v0/address`, {
        params: data,
        headers,
      }),
  };

  const Locations = {
    ...restify("locations", ["create", "readOne", "readMany", "update"]),
    createOutpost: (id, data) =>
      post(`/${MAIN_SERVICE}/locations/${id}/outpost`, data),
    createDeliveryRadius: (id, data) =>
      put(`/${MAIN_SERVICE}/locations/${id}/create-delivery-radius`, data),
    syncOne: (id) => post(`/${MAIN_SERVICE}/pos/locations/${id}/sync`),
    syncAll: () => post(`/${MAIN_SERVICE}/pos/locations/sync`),
    getDeliveryFeeOptions: (id) =>
      get(`/${MAIN_SERVICE}/pos/${id}/delivery-fee-options`),
    getDiningOptions: (id) => get(`/${MAIN_SERVICE}/pos/${id}/dining-options`),
    getPaymentOptions: (id) => {
      if (id)
        return get(`/${MAIN_SERVICE}/pos/${id}/alternative-payment-types`);
      return get(`/${MAIN_SERVICE}/pos/alternative-payment-types`);
    },
    getServiceCharges: (id) =>
      get(`/${MAIN_SERVICE}/pos/${id}/service-charges`),
    getSections: (id) => get(`/${MAIN_SERVICE}/pos/${id}/sections`),
    getOnboardAccount: (id) => get(`/${MAIN_SERVICE}/locations/${id}/onboard`),
    updateOnboardAccount: (id, data) =>
      post(`/${MAIN_SERVICE}/locations/${id}/onboard`, data),
    onboardExternalAccount: (id, data) =>
      post(`/${MAIN_SERVICE}/locations/${id}/external-account`, data),
    sync: (id) => post(`/${MAIN_SERVICE}/toast/locations/${id}/sync`),
    deleteLocationIntegration: (id, integrationTypeName) =>
      destroy(
        `/${MAIN_SERVICE}/locations/${id}/integrations`,
        integrationTypeName,
      ),
    getRevenueCenter: (id) => {
      if (id) return get(`/${MAIN_SERVICE}/pos/${id}/revenue-center`);
    },
  };

  const GhostKitchens = {
    ...restify("ghost-kitchens", ["create", "readOne", "readMany", "update"]),
    getGhostKitchenConfig: (id) => get(`/${MAIN_SERVICE}/ghost-kitchens/${id}`),
    updateGhostKitchenConfig: (id, data) => {
      put(`/${MAIN_SERVICE}/ghost-kitchens/${id}`, data);
    },
  };

  const Loyalties = {
    ...restify("loyalties", ["create", "readMany", "delete"]),
    readManyV1: (data) =>
      get(`/${MAIN_SERVICE}/loyalties-legacy`, { params: data }),
    createBankLoyalty: (data) =>
      post(`/${MAIN_SERVICE}/loyalties/systems/banks`, data),
    createCreditLoyalty: (data) =>
      post(`/${MAIN_SERVICE}/loyalties/systems/credits`, data),
    createTierLoyalty: (data) =>
      post(`/${MAIN_SERVICE}/loyalties/systems/tiers`, data),
    createLoyaltyTrigger: (data) =>
      post(`/${MAIN_SERVICE}/loyalties/systems/optional-triggers`, data),
  };

  const LoyaltyTriggers = {
    ...restify("loyalty-trigger", ["create", "readOne", "readMany", "update"]),
    createLoyaltyTrigger: (data) =>
      post(`/${MAIN_SERVICE}/loyalty-trigger`, data),
    getLoyaltyProgramTierInfo: (data) =>
      get(`/${MAIN_SERVICE}/loyalty-trigger/?groupByThreshold=true`, data),
    updateLoyaltyProgramTierInfo: (data) =>
      put(`/${MAIN_SERVICE}/loyalty-trigger/?groupByThreshold=true`, data),
    updateLoyaltyProgram: (data, id) =>
      put(`/${MAIN_SERVICE}/loyalty-trigger/${id}`, data),
    getBankLoyalties: (data) => get(`/${MAIN_SERVICE}/banks`, data),
    createBankLoyalty: (data) => post(`/${MAIN_SERVICE}/banks`, data),
    updateBankLoyalty: (bankId, data) =>
      put(`/${MAIN_SERVICE}/banks/${bankId}`, data),
    getBankRewards: (bankId, data) =>
      get(`/${MAIN_SERVICE}/rewards/?bank=${bankId}`, { params: data }),
    createBankReward: (data) => post(`/${MAIN_SERVICE}/rewards`, data),
    updateBankReward: (bankRewardId, data) =>
      put(`/${MAIN_SERVICE}/rewards/${bankRewardId}`, data),
  };

  const MenuEntities = {
    updateAvailability: (data) =>
      post(`/${MAIN_SERVICE}/menu-entities/availability`, data),
  };

  const MenuGroups = {
    ...restify("menu-groups", ["readOne", "readMany", "update", "create"]),
  };

  const MenuItems = {
    ...restify("menu-items", ["readOne", "readMany", "update", "create"]),
  };

  const MenuOptions = {
    ...restify("menu-options", ["readOne", "readMany", "update", "create"]),
  };

  const Menus = {
    ...restify("menus", ["readOne", "readMany", "update", "create"]),
    sync: (url, data) => post(`/${MAIN_SERVICE}${url}`, data),
    syncV2: (id) =>
      get(`/${MAIN_SERVICE}/services/menusync/configs/restaurantgroups/${id}`),
    menuSyncJobs: (id) =>
      get(
        `/${MAIN_SERVICE}/services/menusync/restaurantgroups/${id}/syncjobs?limit=1`,
      ),
  };

  const Orders = {
    ...restify("orders", ["readOne", "readMany", "update"]),
    validateRefund: (data) =>
      post(`/${MAIN_SERVICE}/orders/validate-refund`, data),
    issueRefund: (data) => post(`/${MAIN_SERVICE}/orders/issue-refund`, data),
    resendOrderEmail: (data) =>
      post(`/${MAIN_SERVICE}/orders/resend-email`, data),
    voidOrder: (id) => post(`/${MAIN_SERVICE}/orders/${id}/void`),
    updateCoverSize: () => post(`/${MAIN_SERVICE}/orders/update-cover-sizes`),
    getOrdersCSV: (data) =>
      get(`/${MAIN_SERVICE}/orders-csv`, { params: data }),
    syncOrder: (data) => post(`/${MAIN_SERVICE}/orders/sync-order`, data),
  };

  const Patrons = {
    ...restify("patrons", ["readOne", "update"]),
    getLoyalty: (id) => get(`/${MAIN_SERVICE}/patrons/${id}/loyalty`),
    getLoyaltyV1: (id) => get(`/${MAIN_SERVICE}/patrons/${id}/loyalty-legacy`),
    ban: (id, data) => put(`/${MAIN_SERVICE}/patrons/${id}/ban`, data),
  };

  const PermissionGroups = {
    ...restify("permission-groups", [
      "create",
      "readOne",
      "readMany",
      "update",
    ]),
  };

  const Permissions = {
    ...restify("permissions", ["delete"]),
  };

  const PromotionCodes = {
    ...restify("promotion-codes", ["readMany"]),
  };

  const Promotions = {
    ...restify("promotions", ["create", "readOne", "update"]),
    getCodesCSV: (id) => get(`/${MAIN_SERVICE}/promotions/${id}/overview`),
  };

  const PushNotifications = {
    ...restify("push-notifications", ["create", "readOne", "update"]),
    getCepPushNotifications: (data) =>
      get(`/${CEP_SERVICE}/v0/notifications`, { params: data }),
    createCepPushNotifications: (data) =>
      post(`/${CEP_SERVICE}/v0/notifications/push`, data),
    getCepTestPushNotification: (data) =>
      get(`/${CEP_SERVICE}/v0/notifications/push/validate-test`),
    createCepTestPushNotification: (data) =>
      post(`/${CEP_SERVICE}/v0/notifications/push/test`, data),
    updateCepPushNotifications: (id, data) =>
      patch(`/${CEP_SERVICE}/v0/notifications/${id}`, data),
  };

  const AppHomeContents = {
    ...restify("home-content", ["update"]),
    getAppHomeContent: () => get(`/${MAIN_SERVICE}/home-content`),
  };

  const RestaurantGroups = {
    ...restify("restaurant-groups", ["create", "readMany", "update"]),
    readOne: () => get(`/${MAIN_SERVICE}/restaurant-group`),
    syncToast: () => post(`/${MAIN_SERVICE}/pos/partners`),
  };

  const Upsells = {
    ...restify("upsells", ["create", "update", "delete"]),
  };

  const Payments = {
    listTransactions: (id, data) =>
      get(`/${MAIN_SERVICE}/locations/${id}/transactions`, { params: data }),
    listPayouts: (id, data) =>
      get(`/${MAIN_SERVICE}/locations/${id}/payouts`, { params: data }),
    listDisputes: (id, data) =>
      get(`/${MAIN_SERVICE}/locations/${id}/disputes`, { params: data }),
    listRefunds: (id, data) =>
      get(`/${MAIN_SERVICE}/locations/${id}/refunds`, { params: data }),
    getDispute: (id, disputeId) =>
      get(`/${MAIN_SERVICE}/locations/${id}/disputes/${disputeId}`),
    updateDispute: (id, disputeId, data) =>
      patch(`/${MAIN_SERVICE}/locations/${id}/disputes/${disputeId}`, data),
    getPayoutsCSV: (id, data) =>
      get(`/${MAIN_SERVICE}/locations/${id}/payouts/csv`, { params: data }),
    getTransactionsCSV: (id, data) =>
      get(`/${MAIN_SERVICE}/locations/${id}/transactions/csv`, {
        params: data,
      }),
    getDisputesCSV: (data) =>
      get(`/${MAIN_SERVICE}/restaurant-groups/getDisputeList/csv`, {
        params: data,
      }),
    getRefundsCSV: (data) =>
      get(`/${MAIN_SERVICE}/refunds/csv`, { params: data }),
    getPayoutCSV: (locationId, payoutId) =>
      get(`/${MAIN_SERVICE}/locations/${locationId}/payouts/${payoutId}/csv`),
    getOrderByTransaction: (data) =>
      get(`/${MAIN_SERVICE}/orders`, { params: data }),
  };

  const Media = {
    ...restify("media", ["readOne", "readMany", "create", "update", "delete"]),
  };

  const Exports = {
    ...restify("data-exports", ["readMany"]),
  };

  const Tables = {
    ...restify("tables", ["create", "update"]),
    sync: (id) => post(`/${MAIN_SERVICE}/pos/tables/${id}/sync`),
  };

  const AppConfigurations = {
    getWebStaticTextConfigs: (id, data) =>
      get(`/${AUTOMATION_SERVICE}/web-static-texts`, {
        params: data,
      }),
    updateWebStaticTextConfigs: (data) =>
      put(`/${AUTOMATION_SERVICE}/web-static-texts`, data),
    getWebAppConfigs: () => get(`/${AUTOMATION_SERVICE}/web-configurations`),
    updateWebAppConfigs: (data) =>
      put(`/${AUTOMATION_SERVICE}/web-configurations`, data),
    getMobileAppConfigs: () =>
      get(
        `/${AUTOMATION_SERVICE}/app-configurations?platform=mobile_configurations`,
      ),
    getWebOrderingConfigs: () =>
      get(`/${AUTOMATION_SERVICE}/web-configurations`),
    updateWebOrderingConfigs: (data) =>
      put(`/${AUTOMATION_SERVICE}/web-configurations`, data),
    updateMobileAppConfigs: (data) =>
      put(`/${AUTOMATION_SERVICE}/app-configurations`, data),
    updateGlobalConfig: (data) =>
      put(`/${AUTOMATION_SERVICE}/email-configurations`, data),
    getTags: (platform) =>
      get(`/${AUTOMATION_SERVICE}/tags?platform=${platform}`),
    uploadPhotos: (data) =>
      post(`/${AUTOMATION_SERVICE}/upload`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      }),
    getImages: (platform) =>
      get(`/${AUTOMATION_SERVICE}/images?platform=${platform}`),
    getClientConfig: () => get(`/${AUTOMATION_SERVICE}/email-configurations`),
    // Dynamic UI endpoints
    getLocationCardPreview: (userId) =>
      get(
        `/${AUTOMATION_SERVICE}/components?type=LocationCard&adminId=${userId}`,
        {},
      ),
    saveLocationCard: (data) =>
      post(`/${AUTOMATION_SERVICE}/components-confirm`, data, {}),
    getPublishedLocationCard: () =>
      get(`/${AUTOMATION_SERVICE}/components-deploy`, {}),
    publishLocationCard: (data) =>
      post(`/${AUTOMATION_SERVICE}/components-deploy`, data, {}),
  };

  const Marketplaces = {
    ...restify("marketplaces", ["readMany", "create", "update"]),
  };

  const Subscriptions = {
    ...restify("subscription-ledgers", ["delete"]),
    awardSubscription: (id, data) =>
      post(`/${MAIN_SERVICE}/subscription-ledgers/${id}/awards`, data),
  };
  const SubscriptionPeriods = {
    ...restify("subscription-periods", ["readMany"]),
    refund: (id) => put(`/${MAIN_SERVICE}/subscription-periods/${id}/refund`),
  };

  const Taxes = {
    createLocation: (data) => post(`/${MAIN_SERVICE}/location-taxes`, data),
    createItem: (data) => post(`/${MAIN_SERVICE}/item-taxes`, data),
    getLocations: (data) =>
      get(`/${MAIN_SERVICE}/location-taxes`, { params: data }),
    getItems: (data) => get(`/${MAIN_SERVICE}/item-taxes`, { params: data }),
    getLocation: (id, data) =>
      get(`/${MAIN_SERVICE}/location-taxes/${id}`, { params: data }),
    getItem: (id, data) =>
      get(`/${MAIN_SERVICE}/item-taxes/${id}`, { params: data }),
    patchLocation: (id, data) =>
      patch(`/${MAIN_SERVICE}/location-taxes/${id}`, data),
    patchItem: (id, data) => patch(`/${MAIN_SERVICE}/item-taxes/${id}`, data),
  };

  const ServiceCharges = {
    ...restify("pos/service-charges", ["readMany", "create", "delete"]),
    update: (id, data) =>
      patch(`/${MAIN_SERVICE}/pos/service-charges/${id}`, data),
  };
  // endpoints for requesting dns and distributions
  const DNSSetup = {
    requestCertificate: (data) =>
      post(`/${AUTOMATION_SERVICE}/domain-setup/request-certificate`, data),
    getStatus: () => get(`/${AUTOMATION_SERVICE}/domain-setup/status`),
    requestDistribution: (data) =>
      post(`/${AUTOMATION_SERVICE}/domain-setup/request-distribution`, data),

    getConfigs: () => get(`/${AUTOMATION_SERVICE}/configs`),
  };

  const Customers = {
    readMany: (data) => get(`/${CEP_SERVICE}/v0/customers`, { params: data }),
  };

  const Audiences = {
    createAudience: (data) => post(`/${CEP_SERVICE}/v0/audiences`, data),
    deleteAudience: (id) => destroy(`/${CEP_SERVICE}/v0/audiences/${id}`),
    editAudience: (id, data) =>
      patch(`${CEP_SERVICE}/v0/audiences/${id}`, data),
    refreshAudiences: (audienceIds) =>
      post(`/${CEP_SERVICE}/v0/audiences/refresh`, audienceIds),
    fetchCriteria: (data) =>
      get(`/${CEP_SERVICE}/v0/audiences/criterias`, { params: data }),
    readMany: (data) => get(`/${CEP_SERVICE}/v0/audiences`, { params: data }),
    exportCsv: (id, data) =>
      get(`/${CEP_SERVICE}/v0/audiences/${id}/csv`, { params: data }),
  };

  // ================ V2 - DATA POD ENDPOINTS ======================='
  const prefixUrl =
    process.env.NODE_ENV === "development" ? "https://api.lunchbox.dev" : "";
  const Data = {
    getMetadata: (RestaurantgroupId) =>
      get(`${prefixUrl}/${DATA_SERVICE}/metadata`, {
        headers: {
          RestaurantgroupId,
        },
      }),
    readMany: (data, RestaurantgroupId) =>
      get(`${prefixUrl}/${DATA_SERVICE}/metadata`, {
        params: data,
        headers: {
          RestaurantgroupId,
        },
      }),
    createCSVResponse: (data, RestaurantgroupId) =>
      post(`${prefixUrl}/${DATA_SERVICE}/report/sales/export`, data, {
        headers: {
          RestaurantgroupId,
        },
      }),
    getSalesReport: (RestaurantgroupId, salesData, pageNumber = 1) =>
      post(`${prefixUrl}/${DATA_SERVICE}/sales`, salesData, {
        headers: {
          RestaurantgroupId,
        },
        params: {
          page: pageNumber,
        },
      }),

    getReportStatus: (reportId, restaurantgroupId) =>
      get(`${prefixUrl}/${DATA_SERVICE}/report/${reportId}/status`, {
        headers: {
          restaurantgroupId,
        },
      }),
  };

  const Files = {
    readMany: (restaurantGroupId, pageNumber = 1) =>
      get(`${prefixUrl}/${DATA_SERVICE}/report`, {
        params: {
          page: pageNumber,
        },
        headers: {
          restaurantGroupId,
        },
      }),
  };
  const FeatureFlags = {
    getByLocation: (id) => get(`${MAIN_SERVICE}/locations/${id}/flags`, {}),
    getByRestaurantGroup: (id) =>
      get(`${MAIN_SERVICE}/restaurant-groups/${id}/flags`, {}),
    getByMarketPlace: (id) =>
      get(`${MAIN_SERVICE}/marketplaces/${id}/flags`, {}),
    getAllFlags: () => get(`${MAIN_SERVICE}/flags`, {}),
  };
  return {
    Admins,
    Analytics,
    Audit,
    Cards,
    Catering,
    CateringGroups,
    CronActions,
    DiningHours,
    HolidayHours,
    Discounts,
    EmailTemplates,
    GiftCards,
    ItemAvailabilities,
    LiveSearch,
    Locations,
    Loyalties,
    LoyaltyTriggers,
    Media,
    MenuEntities,
    MenuGroups,
    MenuItems,
    MenuOptions,
    Menus,
    Orders,
    Patrons,
    PermissionGroups,
    Permissions,
    PromotionCodes,
    Promotions,
    PushNotifications,
    AppHomeContents,
    RestaurantGroups,
    Upsells,
    Payments,
    Exports,
    Tables,
    AppConfigurations,
    Marketplaces,
    GhostKitchens,
    Subscriptions,
    SubscriptionPeriods,
    Taxes,
    DNSSetup,
    ServiceCharges,
    Customers,
    Audiences,
    Data,
    Files,
    FeatureFlags,
  };
};

export { APIMethods };
