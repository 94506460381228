/* eslint-disable no-restricted-syntax */
import React from "react";
import { Col, Upload, List, message } from "antd";
import { T, Row, Button } from "components/elements";
import { ConfirmationButton } from "components/fragments";
import { useAPIContext } from "components/providers/Api";
import { useActive } from "hooks";
import UploadItem from "./UploadItem";
import { useFileList } from "./useFileList";
import { Upload as UploadIcon } from "@lunchboxinc/icons";

const Uploader = () => {
  const {
    isActive: isUploading,
    activate: setIsUploading,
    deactivate: setNotUploading,
  } = useActive(false);
  const { Media } = useAPIContext();
  const [fileList, fileListSetters, fileListGetters] = useFileList({
    UploadAPI: Media.create,
  });
  const { uploadedCount } = fileListGetters.getUploadedCounts();

  const props = {
    accept: "image/*",
    name: "file",
    multiple: true,
    showUploadList: false,
    beforeUpload: (file) => {
      try {
        fileListSetters.add(file);
        return false;
      } catch (e) {
        console.log(e);
        message.warning(e.message);
        return false;
      }
    },
  };

  const uploadAll = async () => {
    const nonUploadedFiles = fileList.reduce((accu, i, index) => {
      if (i.isUploaded || i.isUploading) return accu;
      return [...accu, fileListSetters.uploadAtIndex(index)];
    }, []);

    if (!nonUploadedFiles.length) {
      message.warn("All files have been uploaded");
      return;
    }

    setIsUploading();
    try {
      for await (const nonUploadedFile of nonUploadedFiles);
      message.success("All files uploaded");
    } catch (e) {
      console.error(e);
      message.error("Unabled to upload all");
    } finally {
      setNotUploading();
    }
  };

  const uploadedAmount = `(${uploadedCount}/${fileList.length})`;

  return (
    <Row gutter={16}>
      <Col style={{ marginBottom: "1rem" }}>
        <Upload.Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <UploadIcon height={40} width={"100%"} />
          </p>
          <p className="ant-upload-text">
            Click or drag file(s) to this area to upload
          </p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload.
          </p>
        </Upload.Dragger>
      </Col>
      {fileList.length > 0 && (
        <Col xs={24} sm={{ offset: 3, span: 18 }} md={{ offset: 6, span: 12 }}>
          <Row type="flex" justify="space-between">
            <Col>
              <T>{`Uploaded ${uploadedAmount}`}</T>
            </Col>
            <Col>
              <ConfirmationButton
                placement="bottom"
                title="Are you sure?"
                okText="Yes"
                onConfirm={fileListSetters.clear}
              >
                <Button color="transparentRed" size="extraSmall">
                  Clear
                </Button>
              </ConfirmationButton>
              &nbsp;
              <Button
                color="blue"
                size="extraSmall"
                disabled={isUploading}
                onClick={uploadAll}
              >
                Upload
              </Button>
            </Col>
          </Row>
          <List
            itemLayout="horizontal"
            dataSource={fileList}
            renderItem={(item, index) => (
              <List.Item key={index}>
                <UploadItem
                  {...item}
                  onRemove={() => fileListSetters.removeAtIndex(index)}
                  onChange={(value) => {
                    fileListSetters.updateAtIndex(index, value);
                  }}
                  onUpload={async () => {
                    try {
                      await fileListSetters.uploadAtIndex(index);
                      message.success(`${item.fileName} was uploaded`);
                    } catch (e) {
                      console.error(e);
                      message.success(
                        `There was an error uploading ${item.fileName}`,
                      );
                    }
                  }}
                />
              </List.Item>
            )}
          />
        </Col>
      )}
    </Row>
  );
};

export default Uploader;
export { Uploader };
