import Pages from "pages";
import withLayout from "components/hocs/withLayout";
import { withLayoutV2 } from "components/v2/HOCs/withLayoutV2";
import {
  globals,
  pagePermissions,
  permissions,
  strings,
} from "common/constants";
import { zendeskArticles } from "common/utils";
import {
  ANALYZE_NAV,
  ENGAGE_NAV,
  OPERATE_NAV,
  SETTINGS_NAV,
} from "./menuConfig";

const { routes, methods } = permissions;
const { MARKETPLACE } = globals;
const layout1 = {
  xs: 24,
};

export const analyzeDashboards = {
  salesOverview: {
    name: "Sales Overview Dashboard",
    path: "/analyze/sales-overview",
    requiredPermissions: routes.ANALYTICS_SALES,
    linkPermissions: [{ method: methods.GET, url: routes.ANALYTICS_SALES }],
    component: withLayoutV2(Pages.SalesOverview, layout1),
    navType: ANALYZE_NAV,
  },
  salesComparaison: {
    name: "Sales Comparison Dashboard",
    path: "/analyze/sales-comparison",
    requiredPermissions: routes.ANALYTICS_SALES,
    linkPermissions: [{ method: methods.GET, url: routes.ANALYTICS_SALES }],
    component: withLayoutV2(Pages.SalesComparison, layout1),
    navType: ANALYZE_NAV,
  },
  mixDashboard: {
    name: "Product Mix Dashboard",
    path: "/analyze/product-mix-dashboard",
    requiredPermissions: routes.ANALYTICS_MENU,
    linkPermissions: [{ method: methods.GET, url: routes.ANALYTICS_MENU }],
    component: withLayoutV2(Pages.MixDashboard, layout1),
    navType: ANALYZE_NAV,
  },
  promotionsDashboard: {
    name: "Promotions Dashboard",
    path: "/analyze/promotions",
    requiredPermissions: routes.ANALYTICS_MENU,
    linkPermissions: [{ method: methods.GET, url: routes.ANALYTICS_MENU }],
    component: withLayoutV2(Pages.PromotionsDashboard, layout1),
    navType: ANALYZE_NAV,
  },
  cohortAnalysisDashboard: {
    name: "Cohort Analysis Dashboard",
    path: "/analyze/cohort-analysis",
    requiredPermissions: routes.GUEST_LOYALTY_REPORTS,
    linkPermissions: pagePermissions.guestAndLoyaltyReports.permissions,
    component: withLayoutV2(Pages.CohortAnalysisDashboard, layout1),
    navType: ANALYZE_NAV,
  },
  guestSegmentationDashboard: {
    name: "Guest Segmentation Dashboard",
    path: "/analyze/guest-segmentation",
    requiredPermissions: routes.GUEST_LOYALTY_REPORTS,
    linkPermissions: pagePermissions.guestAndLoyaltyReports.permissions,
    component: withLayoutV2(Pages.GuestSegmentationDashboard, layout1),
    navType: ANALYZE_NAV,
  },
  daysBetweenOrdersDashboard: {
    name: "Days Between Orders Dashboard",
    path: "/analyze/days-between-orders",
    requiredPermissions: routes.ANALYTICS_MENU,
    linkPermissions: [{ method: methods.GET, url: routes.ANALYTICS_MENU }],
    component: withLayoutV2(Pages.DaysBetweenOrders, layout1),
    navType: ANALYZE_NAV,
  },
  cartSizeDashboard: {
    name: "Cart Size Dashboard",
    path: "/analyze/cart-size",
    requiredPermissions: routes.ANALYTICS_MENU,
    linkPermissions: [{ method: methods.GET, url: routes.ANALYTICS_MENU }],
    component: withLayoutV2(Pages.CartSizeDashboard, layout1),
    navType: ANALYZE_NAV,
  },
  kpiDashboard: {
    name: "Key App KPIs",
    path: "/analyze/kpi-dashboard",
    requiredPermissions: routes.ANALYTICS_SALES,
    linkPermissions: [{ method: methods.GET, url: routes.ANALYTICS_MENU }],
    restrictByClient: [strings.CLEANJUICE],
    component: withLayoutV2(Pages.KpiDashboard, layout1),
    navType: ANALYZE_NAV,
  },
  yearOverYearDashboard: {
    name: "Year-over-Year Review Dashboard",
    path: "/analyze/year-over-year-review",
    requiredPermissions: routes.ANALYTICS_MENU,
    restrictPlatform: MARKETPLACE.toLowerCase(),
    linkPermissions: [{ method: methods.GET, url: routes.ANALYTICS_SALES }],
    component: withLayoutV2(Pages.YearOverYearDashboard, layout1),
    navType: ANALYZE_NAV,
  },

  overview: {
    name: "Analytics Breakdown Dashboard",
    path: "/analyze/overview",
    requiredPermissions: pagePermissions.analyticsOverview.permissions,
    linkPermissions: pagePermissions.analyticsOverview.permissions,
    component: withLayout(Pages.Home.AnalyticsOverview, layout1),
    zendeskArticles: zendeskArticles.HowToUseAdminPanel,
    navType: ANALYZE_NAV,
  },
  sales: {
    name: "Sales Breakdown Dashboard",
    path: "/analyze/sales",
    requiredPermissions: routes.ANALYTICS_SALES,
    linkPermissions: [{ method: methods.GET, url: routes.ANALYTICS_SALES }],
    component: withLayout(Pages.Analytics.SalesAnalytics, layout1),
    navType: ANALYZE_NAV,
  },
  analyticsItem: {
    name: "Item Breakdown Dashboard",
    path: "/analyze/item",
    requiredPermissions: routes.ANALYTICS_MENU,
    linkPermissions: [{ method: methods.GET, url: routes.ANALYTICS_MENU }],
    component: withLayout(Pages.Analytics.ItemAnalytics, layout1),
    navType: ANALYZE_NAV,
  },
  giftCardReports: {
    name: "Gift Card Breakdown Dashboard",
    path: "/analyze/gift-card-reports",
    requiredPermissions: routes.STORED_VALUE_CARDS_REPORTS,
    linkPermissions: [
      { method: methods.GET, url: routes.STORED_VALUE_CARDS_REPORTS },
    ],
    component: withLayout(Pages.Home.GiftCardAnalytics, layout1),
    navType: ANALYZE_NAV,
  },
};

const baseSitemap = {
  salesReports: {
    path: "/analytics/sales-reports",
    requiredPermissions: pagePermissions.salesReports.permissions,
    component: withLayout(Pages.Home.SalesReportsAnalytics, layout1),
    zendeskArticles: zendeskArticles.AdminDashboardUpdates,
    navType: ANALYZE_NAV,
  },
  order: {
    path: "/order/:orderId",
    requiredPermissions: pagePermissions.order.permissions,
    component: withLayout(Pages.Order, layout1),
    navType: OPERATE_NAV,
  },
  orders: {
    path: "/orders",
    requiredPermissions: routes.ORDERS_LIST,
    component: withLayout(Pages.Orders, layout1),
    navType: OPERATE_NAV,
  },
  giftCards: {
    path: "/gift-cards",
    requiredpermissions: routes.STORED_VALUE_CARDS_LIST,
    component: withLayout(Pages.GiftCards, layout1),
    navType: OPERATE_NAV,
  },
  giftCard: {
    path: "/gift-card/:giftCardId",
    requiredPermissions: pagePermissions.giftCard,
    component: withLayout(Pages.GiftCard, layout1),
    navType: OPERATE_NAV,
  },
  exports: {
    path: "/exports",
    requiredpermissions: routes.EXPORTS_LIST,
    component: withLayout(Pages.Exports, layout1),
    navType: ANALYZE_NAV,
  },
  discounts: {
    path: "/discounts",
    requiredPermissions: routes.DISCOUNTS_LIST,
    component: withLayout(Pages.Discounts, layout1),
    zendeskArticles: zendeskArticles.InsideDiscountDetails,
    navType: ENGAGE_NAV,
  },
  discount: {
    path: "/discount/:discountId",
    requiredPermissions: pagePermissions.discount.permissions,
    component: withLayout(Pages.Discount, layout1),
    navType: ENGAGE_NAV,
  },
  marketplaces: {
    path: "/marketplaces",
    requiredPermissions: pagePermissions.marketplaces,
    component: withLayout(Pages.Marketplaces),
    navType: SETTINGS_NAV,
  },
  automationDashboard: {
    path: "/lunchcrew/config",
    requiredPermissions: routes.AUTOMATION,
    component: withLayout(Pages.AutomationDashboard),
  },
  profile: {
    path: "/profile",
    zendeskArticles: zendeskArticles.ViewingUpdatingAdminProfile,
    requiredPermissions: routes.ADMINS_ITEM,
    component: withLayout(Pages.AdminProfile, layout1),
    navType: OPERATE_NAV,
  },
  pushNotifications: {
    path: "/push-notifications",
    requiredPermissions: pagePermissions.pushNotification.permissions,
    component: withLayout(Pages.PushNotifications, layout1),
    navType: ENGAGE_NAV,
  },
  appHomeContent: {
    path: "/app-home-content",
    requiredPermissions: pagePermissions.appHomeContent.permissions,
    component: withLayout(Pages.AppHomeContent, layout1),
    navType: SETTINGS_NAV,
  },
  print: {
    path: "/print",
    component: withLayout(Pages.Print),
    navType: ENGAGE_NAV,
  },
  integrationsEditor: {
    path: "/integrations-editor",
    requiredPermissions: routes.SERVICES,
    component: withLayout(Pages.Integrations, layout1),
    navType: SETTINGS_NAV,
  },
  payout: {
    path: "/payment/:locationId/payouts/:payoutId",
    requiredPermissions: routes.PAYMENTS_ONBOARD,
    component: withLayout(Pages.Payout, layout1),
    navType: SETTINGS_NAV,
  },
  payment: {
    path: "/payment/:locationId",
    requiredPermissions: routes.PAYMENTS_ONBOARD,
    component: withLayout(Pages.Payment, layout1),
    navType: SETTINGS_NAV,
  },
  disputes: {
    path: "/payments/disputes/:id?",
    requiredPermissions: routes.PAYMENTS_ONBOARD,
    component: withLayout(Pages.Disputes, layout1),
    navType: SETTINGS_NAV,
  },
  refunds: {
    path: "/payments/refunds/:id?",
    requiredPermissions: routes.PAYMENTS_ONBOARD,
    component: withLayout(Pages.Refunds, layout1),
    navType: SETTINGS_NAV,
  },
  payments: {
    path: "/payments",
    requiredPermissions: routes.PAYMENTS_ONBOARD,
    component: withLayout(Pages.Payments, layout1),
    navType: SETTINGS_NAV,
  },
  settings: {
    path: "/settings",
    exact: true,
    requiredPermissions: {
      method: methods.PUT,
      url: routes.RESTAURANT_GROUPS_ITEM,
    },
    component: withLayout(Pages.Settings, layout1),
    navType: SETTINGS_NAV,
  },
  role: {
    path: "/role/:id",
    requiredPermissions: pagePermissions.role.permissions,
    component: withLayout(Pages.Role, layout1),
    navType: SETTINGS_NAV,
  },
  roleCreate: {
    path: "/roles/create",
    requiredPermissions: {
      method: methods.POST,
      url: routes.PERMISSION_GROUPS_LIST,
    },
    component: withLayout(Pages.CreateRole, layout1),
    navType: SETTINGS_NAV,
  },
  roles: {
    path: "/roles",
    requiredPermissions: pagePermissions.role.permissions,
    component: withLayout(Pages.Roles, layout1),
    navType: SETTINGS_NAV,
  },
  admins: {
    path: "/admins",
    requiredPermissions: routes.ADMINS_LIST,
    component: withLayout(Pages.Admins, layout1),
    navType: SETTINGS_NAV,
  },
  patron: {
    path: "/patron/:patronId",
    requiredPermissions: routes.PATRONS_ITEM,
    component: withLayout(Pages.Patron, layout1),
    navType: ENGAGE_NAV,
  },
  patrons: {
    path: "/patrons",
    requiredPermissions: routes.PATRONS_LIST,
    component: withLayout(Pages.Patrons, layout1),
    zendeskArticles: zendeskArticles.AwardingLoyalty,
    navType: ENGAGE_NAV,
  },
  promotion: {
    path: "/promotion/:promotionId",
    requiredPermissions: pagePermissions.promotion.permissions,
    component: withLayout(Pages.Promotion, layout1),
  },
  emailTemplates: {
    path: "/email-templates",
    requiredPermissions: pagePermissions.emailTemplates.permissions,
    component: withLayout(Pages.EmailTemplates, layout1),
    navType: SETTINGS_NAV,
  },
  pincodes: {
    path: "/pincodes",
    requiredPermissions: pagePermissions.pincode.permissions,
    component: withLayout(Pages.Pincodes, layout1),
    navType: SETTINGS_NAV,
  },
  cronActions: {
    path: "/cron-actions",
    requiredPermissions: routes.CRON_ACTIONS,
    component: withLayout(Pages.CronActions, layout1),
    navType: SETTINGS_NAV,
  },
  loyaltyPrograms: {
    path: "/loyalty-programs",
    component: withLayout(Pages.LoyaltyProgramDetails, layout1),
    navType: ENGAGE_NAV,
  },
  bankRewards: {
    path: "/bank-rewards",
    component: withLayout(Pages.BankRewards, layout1),
    navType: ENGAGE_NAV,
  },
  loyaltyTriggers: {
    path: "/loyalty-triggers",
    requiredPermissions: [{ method: methods.GET, url: routes.LOYALTY_TRIGGER }],
    component: withLayout(Pages.Loyalties, layout1),
    navType: ENGAGE_NAV,
  },
  dnsSetup: {
    path: "/dns-setup",
    component: withLayout(Pages.DNSSetup),
    navType: SETTINGS_NAV,
  },
  featureFlag: {
    path: "/feature-flag",
    component: withLayoutV2(Pages.FeatureFlagSetup),
    navType: SETTINGS_NAV,
  },
  taxes: {
    path: "/taxes",
    requiredPermissions: [
      { method: methods.GET, url: routes.TAXES_ITEMS_LIST },
      { method: methods.GET, url: routes.TAXES_LOCATIONS_LIST },
    ],
    component: withLayout(Pages.Taxes, layout1),
    navType: SETTINGS_NAV,
  },
  serviceCharges: {
    path: "/service-charges",
    requiredPermissions: routes.SERVICE_CHARGES_LIST,
    component: withLayout(Pages.ServiceCharges),
    navType: SETTINGS_NAV,
  },
  ...analyzeDashboards,
};

const sectionRoutes = {
  analyze: {
    path: "/analyze",
    exact: true,
    requiredPermissions: routes.ANALYTICS_SALES,
    component: withLayoutV2(Pages.SalesOverview, layout1),
    navType: ANALYZE_NAV,
  },
  engage: {
    path: "/engage",
    exact: true,
    requiredPermissions: routes.PATRONS_LIST,
    component: withLayoutV2(Pages.Guests),
    zendeskArticles: zendeskArticles.AwardingLoyalty,
    navType: ENGAGE_NAV,
  },
  operate: {
    path: "/operate",
    exact: true,
    requiredPermissions: routes.ORDERS_LIST,
    component: withLayout(Pages.Orders, layout1),
    navType: OPERATE_NAV,
  },
  /* settings: {
    path: "/settings",
    exact: true,
    requiredPermissions: routes.SERVICES,
    component: withLayout(Pages.Integrations, layout1),
    navType: SETTINGS_NAV,
  }, */
};

const adminSitemap = {
  ...sectionRoutes,
  locationTables: {
    path: "/locations/:locationId/tables",
    requiredPermissions: pagePermissions.tables.permissions,
    component: withLayout(Pages.Tables, layout1),
  },
  location: {
    path: "/locations/:locationId",
    requiredPermissions: routes.LOCATIONS_ITEM,
    component: withLayout(Pages.Location, layout1),
  },
  locations: {
    path: "/locations",
    requiredPermissions: routes.LOCATIONS_LIST,
    component: withLayout(Pages.Locations, layout1),
  },
  themes: {
    path: "/themes",
    requiredPermissions: routes.THEMES,
    component: withLayout(Pages.Themes, layout1),
    zendeskArticles: zendeskArticles.CustomizeHelpCenter,
    navType: SETTINGS_NAV,
  },
  option: {
    path: "/option/:optionId",
    requiredPermissions: routes.MENU_OPTIONS_ITEM,
    component: withLayout(Pages.Option, layout1),
  },
  options: {
    path: "/options",
    requiredPermissions: routes.MENU_OPTIONS_LIST,
    component: withLayout(Pages.Options, layout1),
  },
  item: {
    path: "/item/:itemId",
    requiredPermissions: routes.MENU_ITEMS_ITEM,
    component: withLayout(Pages.Item, layout1),
  },
  items: {
    path: "/items",
    requiredPermissions: routes.MENU_ITEMS_LIST,
    component: withLayout(Pages.Items, layout1),
  },
  group: {
    path: "/group/:groupId",
    requiredPermissions: routes.MENU_GROUPS_ITEM,
    component: withLayout(Pages.Group, layout1),
  },
  groups: {
    path: "/groups",
    requiredPermissions: routes.MENU_GROUPS_LIST,
    component: withLayout(Pages.Groups, layout1),
  },
  menu: {
    path: "/menu/:menuId",
    requiredPermissions: routes.MENU_GROUPS_LIST,
    component: withLayout(Pages.Menu, layout1),
  },
  menus: {
    path: "/menus",
    requiredPermissions: routes.MENUS_LIST,
    component: withLayout(Pages.Menus, layout1),
  },
  media: {
    path: "/media",
    exact: true,
    requiredPermissions: routes.MEDIA_LIST,
    component: withLayout(Pages.Media, layout1),
    navType: SETTINGS_NAV,
  },
  mediaItem: {
    path: "/media/:mediaId",
    requiredPermissions: routes.MEDIA_LIST,
    component: withLayout(Pages.MediaDetails, layout1),
    navType: SETTINGS_NAV,
  },
  upsell: {
    path: "/upsells/:upsellId",
    requiredPermissions: pagePermissions.upsell.permissions,
    component: withLayout(Pages.Upsell, layout1),
  },
  upsells: {
    path: "/upsells",
    requiredPermissions: routes.UPSELLS_LIST,
    component: withLayout(Pages.Upsells, layout1),
  },
  diningHourId: {
    path: "/dining-hour/:diningHourId",
    requiredPermissions: pagePermissions.diningHour.permissions,
    component: withLayout(Pages.DiningHour, layout1),
    navType: OPERATE_NAV,
  },
  diningHour: {
    path: "/dining-hour",
    requiredPermissions: {
      method: methods.POST,
      url: routes.DINING_HOURS_LIST,
    },
    component: withLayout(Pages.DiningHour, layout1),
    navType: OPERATE_NAV,
  },
  diningHours: {
    path: "/dining-hours",
    requiredPermissions: routes.DINING_HOURS_LIST,
    component: withLayout(Pages.DiningHours, layout1),
    navType: OPERATE_NAV,
  },
  holidayHour: {
    path: "/holiday-hour/:holidayHourId",
    requiredPermissions: pagePermissions.holidayHour.permissions,
    component: withLayout(Pages.HolidayHour, layout1),
    navType: OPERATE_NAV,
  },
  holidayHours: {
    path: "/holiday-hours",
    requiredPermissions: routes.HOLIDAY_HOURS_LIST,
    component: withLayout(Pages.HolidayHours, layout1),
    navType: OPERATE_NAV,
  },
  cateringDetails: {
    path: "/catering/:cateringId",
    requiredPermissions: pagePermissions.cateringDetails.permissions,
    component: withLayout(Pages.CateringDetails, layout1),
  },
  catering: {
    path: "/catering",
    requiredPermissions: routes.CATERING_LIST,
    component: withLayout(Pages.Catering, layout1),
  },
  cateringDeliveryZones: {
    path: "/catering-group/:cateringGroupId/delivery-zones",
    requiredPermissions: pagePermissions.cateringGroup.permissions,
    component: withLayout(Pages.CateringGroupZones, layout1),
  },
  cateringGroup: {
    path: "/catering-group/:cateringGroupId",
    requiredPermissions: pagePermissions.cateringGroup.permissions,
    component: withLayout(Pages.CateringGroup, layout1),
  },
  cateringGroups: {
    path: "/catering-groups",
    requiredPermissions: routes.CATERING_GROUPS_LIST,
    component: withLayout(Pages.CateringGroups, layout1),
  },
  restaurantGroup: {
    path: "/restaurant-group",
    requiredPermissions: {
      method: methods.POST,
      url: routes.RESTAURANT_GROUPS_LIST,
    },
    component: withLayout(Pages.RestaurantGroups, layout1),
    navType: SETTINGS_NAV,
  },
  "app-configurations": {
    path: "/app-configuration",
    component: withLayout(Pages.Configurations),
    navType: SETTINGS_NAV,
  },
  "app-themes": {
    path: "/app-configuration/themes",
    component: withLayout(Pages.Themes),
    navType: SETTINGS_NAV,
  },
  subscriptions: {
    path: "/subscriptions",
    requiredPermissions: routes.SUBSCRIPTION_LIST,
    component: withLayout(Pages.Subscriptions),
    navType: OPERATE_NAV,
  },
  subscribers: {
    path: "/subscribers",
    requiredPermissions: routes.SUBSCRIPTION_LIST,
    component: withLayout(Pages.Subscribers),
    navType: OPERATE_NAV,
  },
  "subscription-reports": {
    path: "/subscription-reports/:locationId?",
    requiredPermissions: routes.SUBSCRIPTION_ANALYTICS,
    component: withLayout(Pages.Home.SubscriptionAnalytics),
    navType: ANALYZE_NAV,
  },
  "subscription-daily-reports": {
    path: "/subscription-daily-reports",
    requiredPermissions: routes.SUBSCRIPTION_ANALYTICS,
    component: withLayout(Pages.Home.SubscriptionDailyReports),
    navType: OPERATE_NAV,
  },
};

const marketplaceSitemap = {
  orders: {
    path: "/orders",
    requiredPermissions: routes.MARKETPLACE_ORDERS_LIST,
    component: withLayout(Pages.Orders, layout1),
  },
  ghostKitchen: {
    path: "/kitchen/:id",
    requiredPermissions: routes.GHOST_KITCHEN_ITEM,
    component: withLayout(Pages.GhostKitchen),
  },
  locationTables: {
    path: "/locations/:locationId/tables",
    requiredPermissions: pagePermissions.tables.permissions,
    component: withLayout(Pages.Tables, layout1),
  },
  ghostKitchens: {
    path: "/kitchens",
    requiredPermissions: routes.GHOST_KITCHEN_LIST,
    component: withLayout(Pages.GhostKitchens),
  },
};

// only non-marketplace routes
const newAdminSitemap = {
  salesReport: {
    path: "/analyze/sales-report",
    requiredPermissions: routes.ANALYTICS_SALES_REPORT,
    component: withLayoutV2(Pages.SalesReport),
    navType: ANALYZE_NAV,
  },
};

// only marketplace routes
const newMarketplaceSitemap = {};

// both rg and marketplace routes
const newBaseSitemap = {
  ...sectionRoutes,

  // ENGAGE
  pushNotifications: {
    path: "/engage/push-notifications",
    component: withLayoutV2(Pages.PushNotifications2, layout1),
    navType: ENGAGE_NAV,
  },
  guests: {
    path: "/engage/guests",
    requiredPermissions: routes.PATRONS_LIST,
    component: withLayoutV2(Pages.Guests),
    zendeskArticles: zendeskArticles.AwardingLoyalty,
    navType: ENGAGE_NAV,
  },
  guest: {
    path: "/engage/guest/:guestId",
    requiredPermissions: routes.PATRONS_ITEM,
    component: withLayoutV2(Pages.Guest),
    navType: ENGAGE_NAV,
  },
  audiences: {
    path: "/engage/audiences",
    component: withLayoutV2(Pages.Audiences),
    navType: ENGAGE_NAV,
  },
  createLoyaltySystem: {
    path: "/engage/loyalty-wizard",
    requiredPermissions: [{ method: methods.GET, url: routes.LOYALTY_TRIGGER }],
    component: withLayoutV2(Pages.LoyaltyWizard),
    navType: ENGAGE_NAV,
  },

  // ANALYZE
  dashboards: {
    path: "/analyze/dashboards",
    component: withLayoutV2(Pages.Dashboards),
    navType: ANALYZE_NAV,
  },
  reports: {
    path: "/analyze/reports",
    component: withLayoutV2(Pages.Reports),
    navType: ANALYZE_NAV,
  },
  mixReports: {
    path: "/analyze/product-mix-reports",
    requiredPermissions: routes.ANALYTICS_MENU,
    component: withLayoutV2(Pages.MixReport),
    navType: ANALYZE_NAV,
  },
  modifiersReports: {
    path: "/analyze/modifiers-reports",
    requiredPermissions: routes.ANALYTICS_MENU,
    component: withLayoutV2(Pages.ModifiersReport),
    navType: ANALYZE_NAV,
  },
  reconciliationReports: {
    path: "/analyze/reconciliation-reports",
    requiredPermissions: routes.ANALYTICS_MENU,
    component: withLayoutV2(Pages.ReconciliationReport),
    navType: ANALYZE_NAV,
  },
  guestMetricsReports: {
    path: "/analyze/guest-metrics-reports",
    requiredPermissions: routes.GUEST_LOYALTY_REPORTS,
    component: withLayoutV2(Pages.GuestMetricsReport),
    navType: ANALYZE_NAV,
  },
  areaLoyaltyReports: {
    path: "/analyze/area-loyalty-reports",
    requiredPermissions: routes.GUEST_LOYALTY_REPORTS,
    component: withLayoutV2(Pages.AreaLoyaltyReport),
    navType: ANALYZE_NAV,
  },
  locationLoyaltyReports: {
    path: "/analyze/location-loyalty-reports",
    requiredPermissions: routes.GUEST_LOYALTY_REPORTS,
    component: withLayoutV2(Pages.LocationLoyaltyReport),
    navType: ANALYZE_NAV,
  },
  ...analyzeDashboards,

  // SETTINGS
  companySettings: {
    path: "/settings/company-settings",
    requiredPermissions: {
      method: methods.PUT,
      url: routes.RESTAURANT_GROUPS_ITEM,
    },
    component: withLayout(Pages.Settings, layout1),
    navType: SETTINGS_NAV,
  },
  files: {
    path: "/settings/files",
    component: withLayoutV2(Pages.Files),
    navType: SETTINGS_NAV,
  },
};

export default (platform, newUI) => {
  if (platform === "admin" && !newUI) {
    return { ...baseSitemap, ...adminSitemap };
  }
  if (platform !== "admin" && !newUI) {
    return { ...baseSitemap, ...marketplaceSitemap };
  }
  if (platform === "admin" && newUI) {
    return {
      ...baseSitemap,
      ...adminSitemap,
      ...newAdminSitemap,
      ...newBaseSitemap,
    };
  }
  if (platform !== "admin" && newUI) {
    return {
      ...baseSitemap,
      ...adminSitemap,
      ...marketplaceSitemap,
      ...newBaseSitemap,
    };
  }
};
