import React from "react";
import { Tooltip } from "@lunchboxinc/utensils";
import { TooltipPlacement } from "antd/lib/tooltip";
import styles from "./infoPopoverIcon.module.css";
import { InfoCircle } from "@lunchboxinc/icons";

interface InfoPopover {
  popoverContent?: string;
  popoverPlacement?: TooltipPlacement;
  popoverWidth?: number | string;
}

function InfoPopoverIcon({
  popoverContent = "",
  popoverWidth = 300,
}: InfoPopover) {
  return (
    <Tooltip placement="left" popupLabel={popoverContent}>
      <InfoCircle height={10} width={15} className={styles.infoIcon} />
    </Tooltip>
  );
}

export default InfoPopoverIcon;
