import styled from "styled-components";
import { SelectSearch } from "components/v2";
import { getColor } from "common/constants/theme";
import { Link } from "react-router-dom";

interface IsMobileProps {
  isMobile?: boolean;
}

interface IsActiveProps {
  $isActive: boolean;
}

interface SelectLinkProps {
  $marginBottom?: number;
}

export namespace S {
  export const LogoContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
    padding-right: 15px;
  `;

  export const LogoImage = styled.img<IsMobileProps>`
    width: ${({ isMobile }) => (isMobile ? `32px` : `125px`)};
  `;

  export const MenuButton = styled.div<IsActiveProps>`
    padding-top: 20px;
    padding-bottom: 20px;
    height: 100%;
    vertical-align: middle;
    box-sizing: border-box;
    position: relative;
    &::after {
      ${({ $isActive }) => {
        if ($isActive) {
          return `
            display: inline-block;
            content: "";
            width: 80%;
            height: 3px;
            background-color: ${getColor("yellow", 400)};
            position: absolute;
            top: calc(100% - 3px);
            left: 10%;
            border-radius: 4px;
          `;
        }
      }}
    }
  `;

  export const MenuLink = styled(Link)`
    color: ${getColor("powder")};
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    padding-left: 30px;
    padding-right: 30px;
    &:hover {
      color: ${getColor("yellow", 400)};
    }
    &:focus {
      outline: double;
    }
  `;

  export const RightAlignedButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    align-items: center;
    gap: 10px;
  `;

  export const ProfileContainer = styled.div<IsMobileProps>`
    position: relative;
    ${({ isMobile }) =>
      isMobile &&
      `
      margin-left: 20px;
      margin-right: 10px;
      `}
  `;

  export const ProfileButton = styled.button`
    background-color: transparent;
    margin-right: 15px;
    height: 35px;
    cursor: pointer;
  `;

  export const ProfileIcon = styled.div`
    color: ${getColor("powder")};
    vertical-align: middle;
    font-size: 18px;
    width: auto !important;
  `;

  export const SelectDrawer = styled.div<IsActiveProps>`
    width: 215px;
    z-index: 100;
    position: fixed;
    right: -215px;
    transition: transform 0.3s ease;
    background-color: ${getColor("neutral", 900)};
    border-left: 0.5px solid ${getColor("neutral", 300)};
    align-self: stretch;
    margin-top: 16px;
    min-height: calc(100vh - 131px);
    padding: 20px;
    ${({ $isActive }) => $isActive && `transform: translateX(-100%)`};
  `;

  export const SelectLink = styled(Link)<SelectLinkProps>`
    color: ${getColor("powder")};
    font-size: 14px;
    display: flex;
    margin-bottom: ${({ $marginBottom = 2 }) => $marginBottom}px;
    &:hover {
      color: ${getColor("yellow", 400)};
    }
    &:focus {
      outline: double;
    }
  `;

  export const SelectOptions = styled.div`
    background-color: ${getColor("neutral", 900)};
    position: absolute;
    z-index: 100;
    width: 125px;
    right: 10px;
    border: 0.5px solid ${getColor("neutral", 300)};
    max-height: 200px;
    min-height: 85px;
    overflow-y: auto;
    padding: 20px;
  `;

  export const SettingsLink = styled(Link)<IsActiveProps>`
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    ${({ $isActive }) =>
      $isActive
        ? `border-radius: 50%; background:${getColor(
            "yellow",
            500,
          )}; fill:${getColor("bobblack")};`
        : `border-radius: 50%; background: ""; fill:${getColor(
            "neutral",
            400,
          )};`}
    :hover {
      fill: ${getColor("powder")};
      background: ${getColor("bobblack")};
      border-radius: 50%;
    }
  `;

  export const SettingsIcon = styled.div`
    width: 20px;
  `;

  export const StyledSelectSearch = styled(SelectSearch)`
    background-color: ${getColor("neutral", 600)};
    height: 35px;
    width: 175px;
    color: ${getColor("powder")};
    border: none;
    border-radius: 2px;
    padding-left: 10px;

    ::placeholder {
      color: ${getColor("powder")};
    }
  `;

  export const ToggleSidebarButton = styled.button`
    margin-top: 4px;
    padding-left: 25px;
    padding-right: 25px;
    vertical-align: middle;
  `;

  export const TopbarWrapper = styled.nav`
    background-color: ${getColor("gray", 10)};
    width: 100%;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 100;
    height: 67px;
  `;
}
