import * as React from "react";
import { ORDER_TYPE_TAG_TEXT } from "common/constants/strings";
import { Tag } from "components/elements";
import { Condition } from "components/fragments/Condition";
import styles from "./orderType.module.css";

const tagColorData = {
  dinein: styles.dinein,
  pickup: styles.pickup,
  delivery: styles.delivery,
  kiosk: styles.kiosk,
};
const tagColor = (type) => tagColorData[type] || null;

const tagText = (type) => ORDER_TYPE_TAG_TEXT[type] || null;

const OrderType = ({ type }) => {
  const color = tagColor(type);

  return (
    <Condition.Condition is={color}>
      <Tag className={color}>{tagText(type)}</Tag>
    </Condition.Condition>
  );
};

export default OrderType;
