import * as React from "react";
import { S } from "./styled";
import { NAV_OPTIONS, NAV_MENU_ORDER } from "../menuConfig";
import { getColor } from "common/constants/theme";

const MobileFooter = ({ mobileFooterRef, navType, setNavType }) => {
  const handleMenuOptionClick = (menuType: string) => {
    setNavType(menuType);
  };

  return (
    <S.FooterWrapper ref={mobileFooterRef}>
      {NAV_MENU_ORDER.map((menu) => {
        const { label, icon, menuType, path } = NAV_OPTIONS[menu];

        return (
          <S.FooterButton
            $isActive={navType === menuType}
            role="button"
            key={`footer-${menuType}`}
            onClick={() => {
              handleMenuOptionClick(menuType);
            }}
            to={path}
          >
            <S.Icon>{icon}</S.Icon>
            {label}
          </S.FooterButton>
        );
      })}
    </S.FooterWrapper>
  );
};

export { MobileFooter };
