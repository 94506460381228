import * as React from "react";
import { S } from "./styled";
import { useOnClickOutside } from "hooks";
import { EllipsisVertical, User } from "@lunchboxinc/icons";
import { getColor } from "common/constants/theme";

interface ProfileSelectProps {
  isMobile: boolean;
  handleSettingsClick: () => void;
  onLogout: (e: React.MouseEvent<HTMLElement>) => void;
}

const ProfileSelect = ({
  isMobile,
  handleSettingsClick,
  onLogout,
}: ProfileSelectProps) => {
  const [showOptions, setShowOptions] = React.useState(false);
  const containerRef = React.useRef(null);

  useOnClickOutside(() => {
    setShowOptions(false);
  }, containerRef);

  const handleEditProfileClick = () => {
    setShowOptions(false);
  };

  const handleLogoutClick = (e: React.MouseEvent<HTMLElement>) => {
    setShowOptions(false);
    onLogout(e);
  };

  return (
    <S.ProfileContainer ref={containerRef} isMobile={isMobile}>
      <S.ProfileButton
        aria-label="Profile options"
        aria-expanded={showOptions}
        aria-controls="profile_option_region"
        onClick={() => setShowOptions(!showOptions)}
      >
        <S.ProfileIcon>
          {isMobile ? (
            <EllipsisVertical fill={getColor("powder")} width={5} />
          ) : (
            <User fill={getColor("powder")} width={16} />
          )}
        </S.ProfileIcon>
      </S.ProfileButton>

      {isMobile && (
        <S.SelectDrawer
          id="profile_option_region"
          role="region"
          aria-label="Profile options"
          $isActive={showOptions}
        >
          <S.SelectLink
            to="/integrations-editor"
            onClick={handleSettingsClick}
            $marginBottom={20}
          >
            Settings
          </S.SelectLink>

          <S.SelectLink
            to="/profile"
            onClick={handleEditProfileClick}
            $marginBottom={20}
          >
            Edit Profile
          </S.SelectLink>

          <S.SelectLink to="" onClick={handleLogoutClick} $marginBottom={20}>
            Log Out
          </S.SelectLink>
        </S.SelectDrawer>
      )}

      {showOptions && !isMobile && (
        <S.SelectOptions
          id="profile_option_region"
          aria-label="Profile options"
          role="region"
        >
          <S.SelectLink to="/profile" onClick={handleEditProfileClick}>
            Edit Profile
          </S.SelectLink>
          <S.SelectLink to="" onClick={handleLogoutClick}>
            Log Out
          </S.SelectLink>
        </S.SelectOptions>
      )}
    </S.ProfileContainer>
  );
};

export { ProfileSelect };
