import React, { useState } from "react";
import { Dropdown, Menu } from "antd";
import { Button } from "../../../elements/Button";
import { ConfirmationButton } from "../../../fragments/ConfirmationButton";
import { ChevronDown } from "@lunchboxinc/icons";

const LocationAvailabilityDropwdown = ({ selectedIds, onSelection }) => {
  const [fetching] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(true);

  const onTrigger = () => {
    if (isDropdownVisible) {
      setIsConfirmationVisible(false);
      setIsDropdownVisible(false);
    } else {
      setIsConfirmationVisible(true);
    }
  };

  const onConfirm = () => {
    setIsDropdownVisible(true);
  };

  const onMakeAvailable = () => {
    if (!fetching) {
      onSelection(selectedIds, true);
    }
  };
  const onMakeUnavailable = () => {
    if (!fetching) {
      onSelection(selectedIds, false);
    }
  };

  function handleMenuClick(e) {
    if (e.key === "1") {
      onMakeAvailable();
      setIsDropdownVisible(false);
    } else {
      onMakeUnavailable();
      setIsDropdownVisible(false);
    }
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">Make Available</Menu.Item>
      <Menu.Item key="2">Make Unavailable</Menu.Item>
    </Menu>
  );

  return (
    <ConfirmationButton
      placement="bottom"
      title="This will affect all selected locations. Are you sure?"
      okText="Yes"
      onConfirm={onConfirm}
      visible={isConfirmationVisible}
    >
      <Dropdown
        visible={isDropdownVisible}
        disabled={fetching}
        overlay={menu}
        trigger="click"
      >
        <Button size="small" onClick={onTrigger}>
          Actions <ChevronDown height={10} />
        </Button>
      </Dropdown>
    </ConfirmationButton>
  );
};

export default LocationAvailabilityDropwdown;
