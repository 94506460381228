/* eslint-disable react/jsx-filename-extension */
import * as React from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import "./App.scss";
import { ReactComponent as NoResultsIcon } from "assets/images/no_results_icon.svg";
import { OverlayProvider } from "@react-aria/overlays";
import Routes from "routes";
import { ConfigProvider } from "antd";
import { getAdminSession, clearAdminSession } from "common/utils";
import { AdminSessionSchema } from "common/schemas";
import { theme } from "common/constants/theme";
import { Api } from "./components/providers/Api";
import AlertPopup from "./components/common/AlertPopup";
import {
  User,
  Appcues,
  PagesVisited,
  UIProvider,
} from "./components/providers";
import { AlertProvider } from "./components/providers/Alert";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

function Empty() {
  return (
    <>
      <NoResultsIcon className="noResultsIcon" />
      <div>No Results</div>
    </>
  );
}

const App = () => {
  React.useEffect(() => {
    window.zESettings = {
      webWidget: {
        color: { theme: "#435FF7" },
      },
    };

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=geometry&libraries=drawing`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    // Ensure sessionStorage Value is valid, otherwise reset it
    const adminSession = getAdminSession();
    try {
      AdminSessionSchema.validateSync(adminSession);
    } catch (e) {
      console.log(e);
      clearAdminSession();
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <ReactQueryDevtools />
        <ConfigProvider renderEmpty={() => Empty}>
          <Api.Provider>
            <div className="App">
              <Router basename={process.env.PUBLIC_URL ?? ""}>
                <User.Provider>
                  <PagesVisited.Provider>
                    <Appcues.Provider>
                      <UIProvider>
                        <AlertProvider>
                          <OverlayProvider>
                            <Route>
                              <AlertPopup />
                              <Routes />
                            </Route>
                          </OverlayProvider>
                        </AlertProvider>
                      </UIProvider>
                    </Appcues.Provider>
                  </PagesVisited.Provider>
                </User.Provider>
              </Router>
            </div>
          </Api.Provider>
        </ConfigProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
