import React from "react";
import { Calendar, Row } from "antd";
import { ChevronLeft, ChevronRight } from "@lunchboxinc/icons";
import styles from "./field.module.scss";

const CalendarDatePickerField = ({ label }) => (
  <div className={styles.calendarWrapper}>
    <div className={styles.calendarLabel}>{label}</div>
    <Calendar
      fullscreen={false}
      headerRender={({ value }) => {
        const current = value.clone();
        const localeData = value.localeData();
        const months = [];
        for (let i = 0; i < 12; i++) {
          current.month(i);
          months.push(localeData.monthsShort(current));
        }

        return (
          <Row type="flex" justify="space-between">
            <ChevronLeft height={15} width={"100%"} />
            <div className={styles.calenderHeader}>
              {`${months[value.month()]} ${value.year()}`}
            </div>
            <ChevronRight height={15} width={"100%"} />
          </Row>
        );
      }}
    />
  </div>
);

export default CalendarDatePickerField;
