import React, { useState, useEffect, useRef } from "react";
import { DatePicker, Dropdown, TimePicker } from "antd";
import moment from "moment";
import "moment-timezone";
import classnames from "classnames";
import { utils } from "common";
import styles from "./dropdowns.module.css";
import { ChevronDown } from "@lunchboxinc/icons";

const { fetchParentNode } = utils;

/**
 * By default, moment objects are created in the local time zone.
 * We no longer want to have different timezone
 * Product has decided to default the timezone to Eastern time
 */
moment.tz.setDefault("America/New_York");

const initialState = {
  dateValue: null,
  dateTimeValue: null,
  timeOpen: false,
  visible: false,
  title: "",
};

const createMomentDateTime = (date, time) => {
  const dateTimeString = `${date.format("MM/DD/YYYY")} ${time.format(
    "h:mm a",
  )}`;
  return moment(dateTimeString, "MM/DD/YYYY h:mm a");
};

const DateTimeDropdown = ({
  initialDate,
  onDateValueChange,
  disabledHours,
  disabledMinutes,
  disabledDate,
  presets,
  className,
  end,
}) => {
  const buttonWrapperRef = useRef(null);
  const fetchButtonWrapperRef = () => buttonWrapperRef.current;

  const [dropdownState, setDropdownState] = useState({
    ...initialState,
    ...(initialDate && {
      dateValue: moment(initialDate),
      title: moment(initialDate).format("MM/DD/YYYY h:mm a"),
    }),
    ...(presets[0] &&
      !initialDate && {
        dateValue: presets[0].dateValue,
        title: presets[0].title,
      }),
    ...(!initialDate &&
      !presets[0] && {
        dateValue: moment(),
        title: moment().format("MM/DD/YYYY h:mm a"),
      }),
  });

  const { dateValue, timeOpen, visible, dateTimeValue, title } = dropdownState;

  useEffect(() => {
    if (dateValue) {
      onDateValueChange(dateValue.toISOString());
      setDropdownState({
        ...dropdownState,
        dateTimeValue: title || dateValue.format("MM/DD/YYYY h:mm a"),
      });
    }
  }, [dateValue, setDropdownState]);

  const handleDateChange = (value) => {
    setDropdownState({
      ...dropdownState,
      dateValue: value,
      timeOpen: true,
    });
  };

  const handleTimeChange = (value) => {
    setDropdownState((prevState) => ({
      ...dropdownState,
      dateValue: createMomentDateTime(prevState.dateValue, value),
      title: "",
    }));
  };

  const handleDateOpenChange = (open) => {
    if (!open) {
      setDropdownState({
        ...dropdownState,
        timeOpen: true,
      });
    } else {
      setDropdownState({
        timeOpen: false,
        endValue: null,
      });
    }
  };

  const handleTimeOpenChange = (open) => {
    if (open) {
      setDropdownState({
        ...dropdownState,
        timeOpen: true,
      });
    } else {
      setDropdownState({
        ...dropdownState,
        timeOpen: false,
      });
    }
  };

  const handleVisibleChange = (flag) => {
    setDropdownState({
      ...dropdownState,
      visible: flag,
      timeOpen: false,
    });
  };

  const handlePresetSelection = (dateValue, title) => {
    setDropdownState({
      dateValue,
      title,
      visible: false,
      timeOpen: false,
    });
  };

  const DatePickerInputs = (
    <div className={classnames(styles.dateDropdown, "dateDropdown")}>
      <div className={styles.dateIntervals}>
        {presets.map((preset) => (
          <div
            role="button"
            onClick={() =>
              handlePresetSelection(preset.dateValue, preset.title)
            }
            className={styles.intervalItems}
          >
            {preset.title}
          </div>
        ))}
      </div>

      <div className={classnames(styles.datePickers, "analyticsDatePickers")}>
        {presets[0] && <div className={styles.customRangeTitle}>Custom</div>}
        <DatePicker
          size="small"
          format="M/D/YY"
          value={dateValue}
          placeholder="M/D/YY"
          onChange={handleDateChange}
          onOpenChange={handleDateOpenChange}
          allowClear={false}
          disabledDate={disabledDate}
          getCalendarContainer={fetchButtonWrapperRef}
        />
        <TimePicker
          disabledHours={() => disabledHours(end)}
          disabledMinutes={(current) => disabledMinutes(current, end)}
          format="h:mm a"
          value={dateValue}
          onOpenChange={handleTimeOpenChange}
          open={timeOpen}
          use12Hours
          onChange={handleTimeChange}
          className={styles.timeRangePicker}
          allowClear={false}
          getPopupContainer={fetchButtonWrapperRef}
        />
      </div>
    </div>
  );

  return (
    <div
      className={classnames(styles.buttonWrapper, className)}
      ref={buttonWrapperRef}
    >
      <Dropdown
        placement="bottomCenter"
        trigger={["click"]}
        overlay={DatePickerInputs}
        onVisibleChange={handleVisibleChange}
        visible={visible}
        getPopupContainer={fetchParentNode}
      >
        <div className={styles.hideDateButton}>
          {dateTimeValue}
          <ChevronDown height={10} className={styles.dropdownArrow} />
        </div>
      </Dropdown>
    </div>
  );
};

DateTimeDropdown.defaultProps = {
  presets: [],
};

export default DateTimeDropdown;
