import { lazy } from "react";
import Analytics from "./Analytics";
import { withBreadcrumbs } from "components/hocs/withBreadcrumbs";
import { Hocs } from "../components";
import Home from "./Home";

const { withTitle, withSuspense, withErrorBoundary, withAuditTrail } = Hocs;

const withPageHocs = (component, title) =>
  withErrorBoundary(withSuspense(withTitle(component, title)));

/**
 * @namespace Pages
 */
const LazyLogin = lazy(() => import("./Login"));
const LazyOrders = lazy(() => import("./Orders/Orders"));
const LazyOrder = lazy(() => import("./Orders/Order"));
const LazyLocations = lazy(() => import("./Locations/Locations"));
const LazyLocation = lazy(() => import("./Locations/Location"));
const LazyMenus = lazy(() => import("./Menus/Menus"));
const LazyItems = lazy(() => import("./Items/Items"));
const LazyGroups = lazy(() => import("./Groups"));
const LazyOptions = lazy(() => import("./Options/Options"));
const LazyGiftCards = lazy(() => import("./GiftCards/GiftCards"));
const LazyGiftCard = lazy(() => import("./GiftCards/GiftCard"));
const LazyPatrons = lazy(() => import("./Patrons/Patrons"));
const LazyMenu = lazy(() => import("./Menus/Menu"));
const LazyGroup = lazy(() => import("./Groups/Group"));
const LazyItem = lazy(() => import("./Items/Item"));
const LazyOption = lazy(() => import("./Options/Option"));
const LazyPatron = lazy(() => import("./Patrons/Patron"));
const LazyDiscount = lazy(() => import("./Discounts/Discount"));
const LazyDiscounts = lazy(() => import("./Discounts/Discounts"));
const LazyPromotion = lazy(() => import("./Discounts/Promotions/Promotion"));
const LazyAdmins = lazy(() => import("./Admins"));
const LazyAdminProfile = lazy(() => import("./Admins/AdminProfile"));
const LazyPushNotifications = lazy(() =>
  import("./PushNotifications/PushNotifications"),
);
const LazyAppHomeContent = lazy(() => import("./AppHomeContent"));
const LazyCatering = lazy(() => import("./Catering/Catering"));
const LazyCateringDetails = lazy(() => import("./Catering/CateringDetails"));
const LazyCateringGroup = lazy(() =>
  import("./Catering/CateringGroups/CateringGroup"),
);
const LazyCateringGroups = lazy(() =>
  import("./Catering/CateringGroups/CateringGroups"),
);
const LazyCateringGroupZones = lazy(() =>
  import("./Catering/CateringGroups/CateringGroupZones"),
);
const LazyDiningHour = lazy(() => import("./DiningHours/DiningHour"));
const LazyDiningHours = lazy(() => import("./DiningHours/DiningHours"));
const LazyHolidayHour = lazy(() => import("./HolidayHours/HolidayHour"));
const LazyHolidayHours = lazy(() => import("./HolidayHours/HolidayHours"));
const LazyThemes = lazy(() => import("./Themes"));
const LazyIntegrations = lazy(() => import("./Settings/Integrations"));
const LazyPayments = lazy(() => import("./Settings/Payments"));
const LazyPayment = lazy(() => import("./Settings/Payments/Payment"));
const LazyPrint = lazy(() => import("./Print"));
const LazyPayout = lazy(() => import("./Settings/Payments/Payout"));
const LazySettings = lazy(() => import("./Settings"));
const LazyCreateRole = lazy(() => import("./Roles/CreateRole"));
const LazyRole = lazy(() => import("./Roles/Role"));
const LazyRoles = lazy(() => import("./Roles/Roles"));
const LazyUpsells = lazy(() => import("./Upsells/Upsells"));
const LazyUpsell = lazy(() => import("./Upsells/Upsell"));
const LazyEmailTemplates = lazy(() => import("./EmailTemplates"));
const LazyPincodes = lazy(() => import("./Settings/Pincodes"));
const LazyCronActions = lazy(() => import("./Settings/CronActions"));
const LazyTables = lazy(() => import("./Tables/Tables"));
const LazyRestaurantGroups = lazy(() => import("./RestaurantGroups"));
const LazyDisputes = lazy(() => import("./Settings/Payments/Disputes"));
const LazyDispute = lazy(() => import("./Settings/Payments/Disputes/Dispute"));
const LazyRefunds = lazy(() => import("./Settings/Payments/Refunds"));
const LazyDNSSetup = lazy(() => import("./Settings/DNSSetup/Process"));
const LazyFeatureFlagSetup = lazy(() =>
  import("./Settings/FeatureFlag/FeatureFlag"),
);
const LazyExports = lazy(() => import("./Exports/Exports"));
const LazyConfigurations = lazy(() => import("./Configurations"));
const LazyApps = lazy(() => import("./Apps"));
const LazyDashboards = lazy(() => import("./Dashboards/Dashboards"));
const LazyReports = lazy(() => import("./Reports/Reports"));
const LazySalesReport = lazy(() => import("./SalesReportV2/SalesReport"));
const LazyMarketplaces = lazy(() => import("./Marketplaces"));
const GhostKitchens = lazy(() => import("./GhostKitchens/GhostKitchens"));
const GhostKitchen = lazy(() => import("./GhostKitchens/GhostKitchen"));
const LazyMedia = lazy(() => import("./Media/Media"));
const LazyMediaDetails = lazy(() => import("./Media/MediaDetails"));
const LazyFiles = lazy(() => import("./Files/Files"));
const LazySubscriptions = lazy(() => import("./Subscriptions"));
const LazySubscribers = lazy(() => import("./Subscribers"));
const LazyLoyaltyProgramDetails = lazy(() =>
  import("./Loyalty/LoyaltyPrograms/LoyaltyProgramDetails"),
);
const LazyBankRewards = lazy(() =>
  import("./Loyalty/LoyaltyPrograms/BankLoyalty/BankRewards"),
);
const LazyLoyalties = lazy(() => import("./Loyalty/LoyaltyTriggers"));
const LazyLoyaltyWizard = lazy(() =>
  import("./Loyalty/LoyaltyWizard/LoyaltyWizard"),
);
const LazyTaxes = lazy(() => import("./Taxes/Taxes"));
const LazyServiceCharges = lazy(() => import("./ServiceCharges"));
const LazyGuests = lazy(() => import("./Guests/Guests"));
const LazyGuest = lazy(() => import("./Guests/Guest/Guest"));
const LazyAudiences = lazy(() => import("./Audiences/Audiences"));
const LazyPushNotificationsv2 = lazy(() =>
  import("./PushNotificationsV2/PushNotifications"),
);
const LazyAutomationDashboard = lazy(() => import("./Automation"));
const LazySalesOverview = lazy(() => import("./SalesOverview/SalesOverview"));
const LazySalesComparison = lazy(() =>
  import("./SalesComparisonV2/SalesComparison"),
);
const LazyMixDashboard = lazy(() =>
  import("./MixDashboard/ProductMixDashboard"),
);
const LazyMixReport = lazy(() => import("./MixReports/MixProductReports"));
const LazyReconciliationDashboard = lazy(() =>
  import("./ReconciliationReport/ReconciliationReportDashboard"),
);
const LazyModifiersReport = lazy(() =>
  import("./ModifiersReport/ModifiersReportDashboard"),
);
const LazyCartSize = lazy(() => import("./CartSize/CartSizeDashboard"));
const LazyGuestMetricsReport = lazy(() =>
  import("./GuestMetrics/GuestMetricsReport"),
);
const LazyLocationLoyaltyReport = lazy(() =>
  import("./LocationLoyalty/LocationLoyaltyReport"),
);

const LazyCohortAnalysisDashboard = lazy(() =>
  import("./CohortDashboard/CohortAnalysisDashboard"),
);

const LazyPromotionsDashboard = lazy(() =>
  import("./PromotionsDashboard/PromotionsDashboard"),
);
const LazyGuestSegmentationDashboard = lazy(() =>
  import("./GuestSegmentation/GuestSegmentationDashboard"),
);
const LazyDaysBetweenOrders = lazy(() =>
  import("./DaysBetweenOrdersDashboard/DaysBetweenOrdersDashboard"),
);
const LazyKpiDashboard = lazy(() => import("./Kpi/KpiDashboard"));

const LazyYearOverYearDashboard = lazy(() =>
  import("./YearOverYearDashboard/YearOverYearReviewDashboard"),
);

export default {
  Login: withPageHocs(LazyLogin, "Login"),
  Orders: withPageHocs(LazyOrders, "Orders"),
  Order: withPageHocs(LazyOrder, "Order"),
  Locations: withPageHocs(LazyLocations, "Locations"),
  Location: withPageHocs(
    withAuditTrail(LazyLocation, "locationId"),
    "Location",
  ),

  Admins: withPageHocs(LazyAdmins, "Admins"),
  AutomationDashboard: withPageHocs(
    LazyAutomationDashboard,
    "AutomationDashboard",
  ),
  AdminProfile: withPageHocs(LazyAdminProfile, "Profile"),
  Themes: withPageHocs(LazyThemes, "Themes"),
  Configurations: withPageHocs(
    withAuditTrail(LazyConfigurations, "configurationsId"),
    "Configurations",
  ),
  Settings: withPageHocs(LazySettings, "Settings"),
  CreateRole: withPageHocs(LazyCreateRole, "Create Role"),
  Role: withPageHocs(LazyRole, "Role"),
  Roles: withPageHocs(LazyRoles, "Roles"),
  Integrations: withPageHocs(LazyIntegrations, "Integrations"),
  Payments: withPageHocs(LazyPayments, "Payments"),
  Payment: withPageHocs(LazyPayment, "Payment"),
  Payout: withPageHocs(LazyPayout, "Payout"),
  Disputes: withPageHocs(LazyDisputes, "Disputes"),
  Dispute: withPageHocs(LazyDispute, "Dispute"),
  Refunds: withPageHocs(LazyRefunds, "Refunds"),
  RestaurantGroups: withPageHocs(LazyRestaurantGroups, "Restaurant Groups"),

  Menus: withPageHocs(LazyMenus, "Menus"),
  Groups: withPageHocs(LazyGroups, "Groups"),
  Items: withPageHocs(LazyItems, "Items"),
  Options: withPageHocs(LazyOptions, "Options"),
  Exports: withPageHocs(LazyExports, "Exports"),
  GiftCards: withPageHocs(LazyGiftCards, "Gift Cards"),
  GiftCard: withPageHocs(LazyGiftCard, "Gift Card"),
  Tables: withPageHocs(LazyTables, "Tables"),
  Patrons: withPageHocs(LazyPatrons, "Patrons"),
  Discounts: withPageHocs(LazyDiscounts, "Discounts"),
  Promotion: withPageHocs(LazyPromotion, "Promotion"),
  Catering: withPageHocs(LazyCatering, "Catering"),
  CateringDetails: withPageHocs(LazyCateringDetails, "Catering Details"),
  CateringGroup: withPageHocs(LazyCateringGroup, "Catering Group"),
  CateringGroupZones: withPageHocs(
    LazyCateringGroupZones,
    "Catering Group Zones",
  ),
  CateringGroups: withPageHocs(LazyCateringGroups, "Catering Groups"),
  DiningHour: withPageHocs(LazyDiningHour, "Dining Hour"),
  DiningHours: withPageHocs(LazyDiningHours, "Dining Hours"),
  HolidayHour: withPageHocs(LazyHolidayHour, "Holiday Hour"),
  HolidayHours: withPageHocs(LazyHolidayHours, "Holiday Hours"),
  Upsells: withPageHocs(LazyUpsells, "Upsells"),
  Upsell: withPageHocs(LazyUpsell, "Upsell"),
  EmailTemplates: withPageHocs(LazyEmailTemplates, "Email Templates"),
  Pincodes: withPageHocs(LazyPincodes, "Pincodes"),
  Print: withPageHocs(LazyPrint, "Print"),
  CronActions: withPageHocs(LazyCronActions, "Cron Actions"),

  Media: withPageHocs(LazyMedia, "Media"),
  MediaDetails: withPageHocs(LazyMediaDetails, "Media Details"),
  Files: withPageHocs(LazyFiles, "Files"),

  Menu: withPageHocs(
    withAuditTrail(withBreadcrumbs(LazyMenu), "menuId"),
    "Menu",
  ),
  Group: withPageHocs(
    withAuditTrail(withBreadcrumbs(LazyGroup), "groupId"),
    "Group",
  ),
  Item: withPageHocs(
    withAuditTrail(withBreadcrumbs(LazyItem), "itemId"),
    "Item",
  ),
  Option: withPageHocs(
    withAuditTrail(withBreadcrumbs(LazyOption), "optionId"),
    "Option",
  ),
  Patron: withPageHocs(withAuditTrail(LazyPatron, "patronId"), "Patron"),
  Discount: withPageHocs(
    withAuditTrail(LazyDiscount, "discountId"),
    "Discount",
  ),
  PushNotifications: withPageHocs(LazyPushNotifications, "Push Notifications"),
  Home: Object.fromEntries(
    Object.entries(Home).map(([key, value]) => [
      key,
      withPageHocs(value, "Sales"),
    ]),
  ),

  AppHomeContent: withPageHocs(LazyAppHomeContent, "App Home Content"),

  Analytics: Object.fromEntries(
    Object.entries(Analytics).map(([key, value]) => [
      key,
      withPageHocs(value, "Analytics"),
    ]),
  ),
  Dashboards: withPageHocs(LazyDashboards, "Dashboards"),
  Reports: withPageHocs(LazyReports, "Reports"),
  SalesReport: withPageHocs(LazySalesReport, "SalesReport"),
  SalesOverview: withPageHocs(LazySalesOverview, "Sales Overview"),
  SalesComparison: withPageHocs(LazySalesComparison, "SalesComparison"),
  MixDashboard: withPageHocs(LazyMixDashboard, "Product Mix Dashboard"),
  MixReport: withPageHocs(LazyMixReport, "Product Mix Report"),
  ModifiersReport: withPageHocs(LazyModifiersReport, "Modifiers Reports"),
  ReconciliationReport: withPageHocs(
    LazyReconciliationDashboard,
    "Reconciliation Dashboard",
  ),
  GuestMetricsReport: withPageHocs(
    LazyGuestMetricsReport,
    "Guest Metrics Report",
  ),
  AreaLoyaltyReport: withPageHocs(
    LazyLocationLoyaltyReport,
    "Area Loyalty Report",
  ),
  LocationLoyaltyReport: withPageHocs(
    LazyLocationLoyaltyReport,
    "Location Loyalty Report",
  ),
  GuestSegmentationDashboard: withPageHocs(
    LazyGuestSegmentationDashboard,
    "Guest Segmentation Dashboard",
  ),
  DaysBetweenOrders: withPageHocs(
    LazyDaysBetweenOrders,
    "Days Between Orders Dashboard",
  ),
  PromotionsDashboard: withPageHocs(
    LazyPromotionsDashboard,
    "Promotions Dashboard",
  ),
  CohortAnalysisDashboard: withPageHocs(
    LazyCohortAnalysisDashboard,
    "Cohort Analysis Dashboard",
  ),
  KpiDashboard: withPageHocs(LazyKpiDashboard, "Clean Juice Key App KPIs"),
  YearOverYearDashboard: withPageHocs(
    LazyYearOverYearDashboard,
    "Year-over-Year Review Dashboard",
  ),
  CartSizeDashboard: withPageHocs(LazyCartSize, "Cart Size"),
  Marketplaces: withPageHocs(LazyMarketplaces, "Marketplaces"),
  GhostKitchens: withPageHocs(GhostKitchens, "Kitchens"),
  GhostKitchen: withPageHocs(GhostKitchen, "Kitchen"),
  Apps: withPageHocs(LazyApps, "Client Apps"),
  Subscriptions: withPageHocs(LazySubscriptions, "Subscriptions"),
  Subscribers: withPageHocs(LazySubscribers, "Subscribers"),
  LoyaltyProgramDetails: withPageHocs(
    LazyLoyaltyProgramDetails,
    "Loyalty Program Details",
  ),
  BankRewards: withPageHocs(LazyBankRewards, "Bank Rewards"),
  Loyalties: withPageHocs(LazyLoyalties, "Loyalties"),
  LoyaltyWizard: withPageHocs(LazyLoyaltyWizard, "Loyalty Wizard"),
  Taxes: withPageHocs(LazyTaxes, "Taxes"),
  DNSSetup: withPageHocs(LazyDNSSetup, "DNSSetup"),
  FeatureFlagSetup: withPageHocs(LazyFeatureFlagSetup, "FeatureFlagSetup"),
  ServiceCharges: withPageHocs(LazyServiceCharges, "Service Charges"),

  Guests: withPageHocs(LazyGuests, "All Guests"),
  Guest: withPageHocs(withAuditTrail(LazyGuest, "guestId"), "Guest"),
  Audiences: withPageHocs(LazyAudiences, "Audiences"),
  PushNotifications2: withPageHocs(
    LazyPushNotificationsv2,
    "Push Notifications",
  ),
};
