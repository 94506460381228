export default [
  { name: "America/New_York", value: "America/New_York" },
  { name: "America/Chicago", value: "America/Chicago" },
  { name: "America/Denver", value: "America/Denver" },
  { name: "America/Phoenix", value: "America/Phoenix" },
  { name: "America/Los_Angeles", value: "America/Los_Angeles" },
  { name: "America/Anchorage", value: "America/Anchorage" },
  { name: "America/Adak", value: "America/Adak" },
  { name: "Pacific/Honolulu", value: "Pacific/Honolulu" },
];
