import React from "react";
import {
  Bell,
  Clock,
  DollarSign,
  Download,
  FileInvoiceDollar,
  Gear,
  Gift,
  Home,
  List,
  MapMarker,
  Pizza,
  ShoppingBag,
  ShoppingCart,
  Sliders,
  Tachometer,
  Users,
  VolumeHigh,
} from "@lunchboxinc/icons";
import { getColor } from "common/constants/theme";

export const OPERATE_NAV = "operate";
export const ENGAGE_NAV = "engage";
export const ANALYZE_NAV = "analyze";
export const SETTINGS_NAV = "settings";

export interface NavOptionProps {
  label: string;
  icon?: React.ReactNode;
  menuType: string;
  path?: string;
}

export interface SideMenuItem {
  disabled: boolean;
  label: string;
  icon?: React.ReactNode;
  path?: string;
  restrictProduction?: boolean;
  subItems?: SideMenuItem[];
  superAdminOnly?: boolean;
}

export type SideNavItem = {
  isHoverExpanded?: boolean;
  isSettingsMenu: boolean;
  isSubmenu?: boolean;
  menuDropdownOpen?: string;
  menuItem: SideMenuItem;
  minNav: boolean;
  setMenuDropdownOpen: Function;
};

export const NAV_OPTIONS = {
  [ANALYZE_NAV]: {
    label: "Analyze",
    icon: <Tachometer />,
    menuType: ANALYZE_NAV,
    path: "/analyze",
  },
  [ENGAGE_NAV]: {
    label: "Engage",
    icon: <VolumeHigh />,
    menuType: ENGAGE_NAV,
    path: "/engage",
  },
  [OPERATE_NAV]: {
    label: "Operate",
    icon: <Pizza />,
    menuType: OPERATE_NAV,
    path: "/operate",
  },
  [SETTINGS_NAV]: {
    label: "Settings",
    icon: <Gear />,
    menuType: SETTINGS_NAV,
    path: "/settings",
  },
};

export const NAV_MENU_ORDER = [OPERATE_NAV, ENGAGE_NAV, ANALYZE_NAV];

export const NAV_MENUS = {
  [ANALYZE_NAV]: [
    {
      label: "Dashboards",
      icon: <Home width={25} height={25} />,
      path: "/analyze/dashboards",
    },
    {
      label: "Reports",
      icon: <FileInvoiceDollar width={25} height={25} />,
      path: "/analyze/reports",
    },
    {
      label: "Exports",
      path: "/exports",
      icon: <Download width={25} height={25} />,
      restrictProduction: true,
    },
  ],
  [ENGAGE_NAV]: [
    {
      label: "All Guests",
      icon: <Users width={25} height={25} />,
      path: "/engage/guests",
    },
    {
      label: "Audiences",
      icon: <Sliders width={25} height={25} />,
      path: "/engage/audiences",
    },
    {
      label: "Push Notifications",
      icon: <VolumeHigh width={25} height={25} />,
      path: "/engage/push-notifications",
    },
    {
      label: "Loyalty",
      icon: <ShoppingBag width={25} height={25} />,
      path: "#",
      subItems: [
        { label: "Loyalty Triggers", path: "/loyalty-triggers" },
        {
          label: "Loyalty Wizard",
          path: "/engage/loyalty-wizard",
          superAdminOnly: true,
        },
      ],
    },
    {
      label: "Discounts",
      icon: <DollarSign width={25} height={25} />,
      path: "/discounts",
    },
  ],
  [OPERATE_NAV]: [
    {
      label: "Orders",
      icon: <ShoppingCart width={25} height={25} />,
      path: "#",
      subItems: [
        { label: "All Orders", path: "/orders" },
        { label: "Catering", path: "/catering" },
        { label: "Catering Groups", path: "/catering-groups" },
      ],
    },
    {
      label: "Menus",
      icon: <List width={25} height={25} />,
      path: "#",
      subItems: [
        { label: "Menus", path: "/menus" },
        { label: "Groups", path: "/groups" },
        { label: "Items", path: "/items" },
        { label: "Options", path: "/options" },
        { label: "Upsells", path: "/upsells" },
      ],
    },
    {
      label: "Hours",
      icon: <Clock width={25} height={25} />,
      path: "#",
      subItems: [
        { label: "Dining Hours", path: "/dining-hours" },
        { label: "Holiday Hours", path: "/holiday-hours" },
      ],
    },
    {
      label: "Locations",
      icon: <MapMarker width={25} height={25} />,
      path: "/locations",
    },
    {
      label: "Subscriptions",
      icon: <Bell width={25} height={25} />,
      path: "#",
      subItems: [
        { label: "Schedule", path: "/subscriptions" },
        { label: "Subscribers", path: "/subscribers" },
        { label: "Daily Summary", path: "/subscription-daily-reports" },
      ],
    },
    {
      label: "Gift Cards",
      icon: <Gift width={25} height={25} />,
      path: "/gift-cards",
    },
  ],
  [SETTINGS_NAV]: [
    { label: "Integrations", path: "/integrations-editor" },
    { label: "Payments", path: "/payments" },
    { label: "Taxes", path: "/taxes" },
    { label: "Service Charges", path: "/service-charges" },
    { label: "Company Settings", path: "/settings/company-settings" },
    { label: "Roles", path: "/roles" },
    { label: "Email Templates", path: "/email-templates" },
    { label: "Media", path: "/media" },
    { label: "Home Content", path: "/app-home-content" },
    {
      label: "Files",
      path: "/settings/files",
      restrictProduction: true,
    },
    { label: "Marketplaces", path: "/marketplaces" },
    { label: "Pincodes", path: "/pincodes" },
    { label: "Cron Actions", path: "/cron-actions", superAdminOnly: true },
    { label: "Apps", path: "/apps", restrictProduction: true, disabled: true },
    { label: "DNS Setup", path: "/dns-setup", superAdminOnly: true },
    { label: "Feature Flag", path: "/feature-flag", superAdminOnly: true },
    { label: "Restaurant Group", path: "/restaurant-group" },
    { label: "Admins", path: "/admins" },
    {
      label: "App Config",
      path: "#",
      subItems: [
        { label: "Configurations", path: "/app-configuration" },
        { label: "Themes", path: "/themes" },
      ],
    },
  ],
};
