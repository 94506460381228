import * as React from "react";
import { Col } from "antd";
import { Divider, Tooltip } from "@lunchboxinc/utensils";
import { Link } from "react-router-dom";
import { Row, ConditionalWrapper } from "components/elements";
import { Condition as Conditions, Icons } from "components/fragments";
import { convert2NegativeValue } from "common/utils";
import { strings } from "common/constants";
import { getServiceChargeLabel, DELIVERY_SERVICE_CHARGE_INFO } from "./utils";
import PaymentSummaryRow from "./PaymentSummaryRow";
import styles from "./paymentSummary.module.css";
import { InfoCircle } from "@lunchboxinc/icons";

const { Condition } = Conditions;

const PaymentRow = (props) => (
  <PaymentSummaryRow className={styles.paymentRow} {...props} />
);
const RefundedPaymentRow = (props) => (
  <PaymentSummaryRow className={styles.refundedPaymentsRow} {...props} />
);

const PaymentSummary = ({
  appliedCredit,
  appliedStoredValue = 0,
  giftCardTotalValue = 0,
  creditRefunded,
  deliveryAmount,
  discount,
  discountAmount,
  finalAmount = 0,
  finalRefund,
  paymentRefunded,
  giftCardRefunded,
  totalRefunded,
  refundableCreditCardAmount = 0,
  taxAmount,
  tipAmount,
  totalPrice,
  giftCardType,
  promotionCode,
  orderType,
}) => {
  const finalAmountWithGCandSC =
    finalAmount + appliedStoredValue + giftCardTotalValue;

  return (
    <Row spacing={30} gutter={15}>
      <Col xs={24} sm={24}>
        <Row spacing={15} className={styles.paymentHeader}>
          <Col xs={18} className={styles.paymentHeaderText}>
            <h3>
              <Icons.IconText
                color="white"
                type="shopping"
                text="Ticket Summary"
              />
            </h3>
          </Col>
        </Row>

        <PaymentRow label="Amount" value={totalPrice} />

        <Condition is={deliveryAmount}>
          <PaymentRow
            label={getServiceChargeLabel(orderType)}
            value={deliveryAmount}
            info={
              <Condition is={orderType === strings.ORDER_TYPE.DELIVERY}>
                <Tooltip popupLabel={DELIVERY_SERVICE_CHARGE_INFO}>
                  <button>
                    <InfoCircle height={10} width={15} />
                  </button>
                </Tooltip>
              </Condition>
            }
          />
        </Condition>

        <Condition is={discountAmount}>
          <ConditionalWrapper
            condition={discount}
            wrapper={(children) => (
              <Link to={`/discount/${discount}`}>{children}</Link>
            )}
          >
            <PaymentRow
              label="Discount Applied"
              value={convert2NegativeValue(discountAmount)}
              promo={promotionCode}
            />
          </ConditionalWrapper>
        </Condition>

        <Condition is={appliedCredit}>
          <PaymentRow
            label="Applied Loyalty"
            value={convert2NegativeValue(appliedCredit)}
          />
        </Condition>

        <PaymentRow label="Tax" value={taxAmount} />

        <PaymentRow label="Tip" value={tipAmount} />

        <Row spacing={10} className={styles.paymentRow}>
          <Col xs={18} />
          <Divider />
        </Row>

        <strong>
          <PaymentRow label="Total Amount" value={finalAmountWithGCandSC} />
        </strong>

        <div className={styles.paymentDetailFields}>
          <Condition is={appliedStoredValue}>
            <PaymentRow
              label={`Paid With ${
                giftCardType === strings.GIFTCARD_TYPE.PRELOAD
                  ? "Preload"
                  : "Gift"
              } Card`}
              value={appliedStoredValue}
            />
          </Condition>

          <Condition is={giftCardTotalValue}>
            <PaymentRow
              label="Paid With Gift Card"
              value={giftCardTotalValue}
            />
          </Condition>

          <Condition is={finalAmount}>
            <PaymentRow label="Paid With Card" value={finalAmount} />
          </Condition>
        </div>

        <Condition
          is={
            finalRefund ||
            totalRefunded ||
            paymentRefunded ||
            creditRefunded ||
            giftCardRefunded
          }
        >
          <Row spacing={10} className={styles.paymentRow}>
            <Col xs={18} />
            <Divider />
          </Row>
        </Condition>

        <Condition is={finalRefund || totalRefunded}>
          <strong>
            <RefundedPaymentRow
              label="Total Amount Refunded"
              value={finalRefund || totalRefunded}
            />
          </strong>
        </Condition>
        <div className={styles.paymentDetailFields}>
          <Condition is={creditRefunded}>
            <RefundedPaymentRow
              label="Loyalty Refunded"
              value={creditRefunded}
            />
          </Condition>
          <Condition is={giftCardRefunded}>
            <RefundedPaymentRow
              label="Total Gift Card & Preload Card Refunded"
              value={giftCardRefunded}
            />
          </Condition>
          <Condition is={paymentRefunded || refundableCreditCardAmount}>
            <RefundedPaymentRow
              label="Payment Refunded"
              value={paymentRefunded || refundableCreditCardAmount}
            />
          </Condition>
        </div>
      </Col>
    </Row>
  );
};

export default PaymentSummary;
