import React from "react";
import classnames from "classnames";
import { Fragments } from "components";
import styles from "./integrationsTable.module.css";
import { ChevronRight } from "@lunchboxinc/icons";

const {
  Tables: { Table },
} = Fragments;

const ExpandableTable = ({
  children,
  expandedRowRender,
  className,
  onRow,
  selectionKey,
  ...props
}) => {
  const [expandedRowKeys, setExpandedRowKeys] = React.useState([]);

  const selectedExpandIcon = (rowProps) =>
    rowProps.expanded ? styles.selectedExpandIcon : styles.expandIcon;

  return (
    <Table
      {...props}
      className={classnames(styles.ExpandableTable, className)}
      expandedRowKeys={expandedRowKeys}
      expandedRowRender={expandedRowRender}
      expandIcon={(rowProps) => (
        <ChevronRight
          height={15}
          width={15}
          className={selectedExpandIcon(rowProps)}
        />
      )}
      onRow={(record) => ({
        onClick: () => {
          if (expandedRowKeys[0] === record[selectionKey]) {
            setExpandedRowKeys([]);
          } else {
            setExpandedRowKeys([record[selectionKey]]);
          }
          if (onRow) {
            onRow(record);
          }
        },
      })}
    >
      {children}
    </Table>
  );
};

export default ExpandableTable;
