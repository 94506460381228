import * as React from "react";
import debounce from "lodash/debounce";
import { Col, InputNumber, message, Popconfirm } from "antd";
import { Divider } from "@lunchboxinc/utensils";
import { LoadingSpinner } from "components/v2/Loading/LoadingSpinner/LoadingSpinner";
import { Row, Price, Button, Titles, T } from "components/elements";
import { utils, constants } from "common";
import { useAPIContext } from "components/providers/Api";
import Drawers from "../../fragments/Drawers";
import { Permission } from "../../fragments/Permission";
import { useUserContext } from "components/providers/User";
import styles from "./paymentSummary.module.css";
import { DollarSign } from "@lunchboxinc/icons";

const { mapAxiosError } = utils;

const { permissions } = constants;
const {
  methods: { PUT },
  routes: { ORDERS_ITEM },
} = permissions;

const Refund = ({
  appliedCredit,
  orderId,
  paymentRefunded: initPayment,
  creditRefunded: initCredit,
  finalAmount: finalAmount,
  giftCardRefunded: initGiftCard,
  totalRefunded: initTotalRefunded,
  amount,
  taxAmount,
  tipAmount,
  deliveryAmount,
  onDrawerClose,
}) => {
  const { Orders } = useAPIContext();
  const {
    restaurantGroup: { featureFlags },
  } = useUserContext();
  const { isRefundSyncEnabled } = featureFlags ?? {
    isRefundSyncEnabled: false,
  };
  const [refund, setRefund] = React.useState(0);
  const [pendingManualPaymentRefund, setPendingManualPaymentRefund] =
    React.useState(0);
  const [pendingPaymentRefund, setPendingPaymentRefund] = React.useState(0);
  const [pendingCreditRefund, setPendingCreditRefund] = React.useState(0);
  const [pendingGiftCardRefund, setPendingGiftCardRefund] = React.useState(0);
  const [validatingRefund, setValidatingRefund] = React.useState(false);
  const [issuingRefund, setIssuingRefund] = React.useState(false);
  const [syncingOrder, setSyncingOrder] = React.useState(false);
  const [totalRefunded, setTotalRefunded] = React.useState(initTotalRefunded);
  const [creditRefunded, setCreditRefunded] = React.useState(initCredit);
  const [paymentRefunded, setPaymentRefunded] = React.useState(initPayment);
  const [giftCardRefunded, setGiftCardRefunded] = React.useState(initGiftCard);

  const [visible, setVisible] = React.useState(false);

  const refundFieldProps = {
    className: "refundInput",
    size: "small",
    style: { width: "100%" },
    formatter: (value) => `$ ${value}`,
    min: 0,
    max: parseFloat(
      (
        amount +
        taxAmount +
        tipAmount +
        deliveryAmount -
        creditRefunded
      ).toFixed(2),
    ),
    step: 0.01,
  };

  React.useEffect(() => {
    setPaymentRefunded(initPayment);
    setCreditRefunded(initCredit);
    setGiftCardRefunded(initGiftCard);
  }, [initPayment, initCredit]);

  const handleDrawerClose = () => {
    setVisible(false);
    onDrawerClose();
  };

  const updateOrder = async () => {
    const refundAmount = pendingManualPaymentRefund;
    setIssuingRefund(true);
    try {
      const res = await Orders.update(orderId, { refunded: refundAmount });
      message.success("Manual Refund Successful");
      setPaymentRefunded(res.data.refunded);
    } catch (e) {
      message.error(mapAxiosError(e));
    } finally {
      setIssuingRefund(false);
    }
  };

  const validateRefund = React.useRef(
    debounce(async (refundAmount, isRefundedOverride) => {
      const refundRegex = /^[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]*)?$/;
      if (refundRegex.test(refundAmount)) {
        setValidatingRefund(true);
        try {
          const res = await Orders.validateRefund({ refundAmount, orderId });
          const {
            finalRefund,
            refundablePayment,
            refundableCredit,
            refundableGiftCard,
          } = res.data;
          if (isRefundedOverride) {
            setPendingPaymentRefund(0);
            setPendingCreditRefund(0);
            setPendingGiftCardRefund(0);
            setRefund(0);
            setPendingManualPaymentRefund(finalRefund);
          } else {
            setPendingManualPaymentRefund(0);
            setRefund(finalRefund);
            setPendingPaymentRefund(refundablePayment);
            setPendingCreditRefund(refundableCredit);
            setPendingGiftCardRefund(refundableGiftCard);
          }
        } catch (e) {
          message.error(mapAxiosError(e));
        } finally {
          setValidatingRefund(false);
        }
      }
    }, 750),
  );

  const issueRefund = async () => {
    const refundAmount = refund;
    setIssuingRefund(true);
    try {
      const res = await Orders.issueRefund({ refundAmount, orderId });
      const {
        paymentRefunded: paymentRefund,
        creditRefunded: creditRefund,
        refundedGiftCardAmount: giftCardRefund,
        finalRefund,
      } = res.data;
      message.success("Refund Processed Successfully");
      // There is no totalRefunded equivalent in the issue-refund response.
      // Instead, we add the total refunded amount from this refund (finalRefund)
      // to the previous totalRefunded amount.
      setTotalRefunded((prevState) => prevState + finalRefund);
      setPaymentRefunded(paymentRefund);
      setCreditRefunded(creditRefund);
      setGiftCardRefunded(giftCardRefund);
      setRefund(0);
      setPendingPaymentRefund(0);
      setPendingCreditRefund(0);
      setPendingGiftCardRefund(0);
    } catch (e) {
      message.error(mapAxiosError(e));
    } finally {
      setIssuingRefund(false);
    }
  };

  const syncOrder = async () => {
    setSyncingOrder(true);
    try {
      const res = await Orders.syncOrder({ orderId });
      const {
        paymentRefunded: paymentRefund,
        creditRefunded: creditRefund,
        refundedGiftCardAmount: giftCardRefund,
        finalRefund,
      } = res?.data;

      message.success("Order Synced Successfully");
      setTotalRefunded((prevState) => prevState + finalRefund);
      setPaymentRefunded(paymentRefund);
      setCreditRefunded(creditRefund);
      setGiftCardRefunded(giftCardRefund);
    } catch (e) {
      message.error(mapAxiosError(e));
    } finally {
      setSyncingOrder(false);
    }
  };

  const button = (
    <Button
      className={styles.refundButton}
      size="small"
      color="blue"
      onClick={() => setVisible(true)}
    >
      Refund
    </Button>
  );

  return (
    <Drawers.ResDrawer
      title={
        <>
          <DollarSign height={15} />
          <Titles.CardTitle className={styles.paymentSummaryTitle}>
            {" Payment Summary"}
          </Titles.CardTitle>
        </>
      }
      placement="right"
      visible={visible}
      onClose={handleDrawerClose}
      width="35vw"
      button={button}
    >
      <>
        <Col>
          <Row spacing={10}>
            <strong>
              <Col xs={18}>Original Total</Col>
              <Col xs={6}>
                <Price value={finalAmount + appliedCredit} />
              </Col>
            </strong>
          </Row>
          <Row spacing={10}>
            <strong>
              <Col xs={18}>Total Amount Refunded</Col>
              <Col xs={6}>
                <Price value={totalRefunded} />
              </Col>
            </strong>
          </Row>

          <div className={styles.refundDetailsFields}>
            <Row spacing={10}>
              <Col xs={18}>Payments Refunded</Col>
              <Col xs={6}>
                <Price value={Math.abs(paymentRefunded)} />
              </Col>
            </Row>
            <Row spacing={10}>
              <Col xs={18}>Loyalty Refunded</Col>
              <Col xs={6}>
                <Price value={creditRefunded} />
              </Col>
            </Row>
            <Row spacing={10}>
              <Col xs={18}>Gift Card Amount Refunded</Col>
              <Col xs={6}>
                <Price value={giftCardRefunded} />
              </Col>
            </Row>
          </div>

          {!isRefundSyncEnabled ? (
            <>
              <Row>
                <Col xs={6}>
                  <T className={styles.issueRefund}>Issue Refund</T>
                </Col>
                <Col xs={18}>
                  <Divider />
                </Col>
              </Row>

              <Row spacing={7}>
                <strong>
                  <Col xs={18}>Total Refund Amount</Col>
                  <Col xs={6}>
                    <InputNumber
                      value={refund}
                      onChange={(value) => {
                        if (value < 0.01) value = 0;
                        if (refund !== value) validateRefund.current(value);
                      }}
                      {...refundFieldProps}
                    />
                  </Col>
                </strong>
              </Row>

              <div className={styles.refundDetailsFields}>
                <Row spacing={10}>
                  <Col xs={18}>Refundable Payment</Col>
                  <Col xs={6}>
                    <LoadingSpinner spinning={validatingRefund} size="small">
                      <Price value={pendingPaymentRefund} />
                    </LoadingSpinner>
                  </Col>
                </Row>

                <Row spacing={10}>
                  <Col xs={18}>Refundable Loyalty</Col>
                  <Col xs={6}>
                    <LoadingSpinner spinning={validatingRefund} size="small">
                      <Price value={pendingCreditRefund} />
                    </LoadingSpinner>
                  </Col>
                </Row>

                <Row spacing={10}>
                  <Col xs={18}>Refundable Gift Card Amount</Col>
                  <Col xs={6}>
                    <LoadingSpinner spinning={validatingRefund} size="small">
                      <Price value={pendingGiftCardRefund} />
                    </LoadingSpinner>
                  </Col>
                </Row>
              </div>
            </>
          ) : null}
          <Divider />

          <Row>
            <Col xs={24}>
              {isRefundSyncEnabled ? (
                <Popconfirm
                  placement="top"
                  title={
                    <React.Fragment>
                      <strong>
                        Are you sure you want to sync the refunds of this order
                        from the POS?
                      </strong>
                    </React.Fragment>
                  }
                  onConfirm={() => syncOrder()}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    className={styles.processRefundButton}
                    size="medium"
                    color="blue"
                    disabled={issuingRefund || validatingRefund}
                    loading={issuingRefund}
                  >
                    Sync Refund
                  </Button>
                </Popconfirm>
              ) : (
                <Popconfirm
                  placement="top"
                  title={
                    <React.Fragment>
                      <strong>Please Confirm these refund amounts</strong>
                      <div>{`$${pendingPaymentRefund} will be refunded to the guests payment method`}</div>
                      <div>{`$${pendingCreditRefund} will be refunded to the guest in loyalty points`}</div>
                    </React.Fragment>
                  }
                  onConfirm={() => issueRefund()}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    className={styles.processRefundButton}
                    size="medium"
                    color="blue"
                    disabled={issuingRefund || validatingRefund}
                    loading={issuingRefund}
                  >
                    Process Refund
                  </Button>
                </Popconfirm>
              )}
            </Col>
          </Row>

          <Permission
            requiredPermissions={{
              method: PUT,
              url: ORDERS_ITEM,
              field: "refunded",
            }}
            yes={() => (
              <Row spacing={20}>
                <Col xs={12}>
                  <T>Manual Refund</T>
                  <Col xs={12}>
                    <InputNumber
                      value={pendingManualPaymentRefund}
                      onChange={(value) => {
                        if (value < 0.01) value = 0;
                        if (paymentRefunded !== value && value > 0) {
                          validateRefund.current(value, true);
                        }
                      }}
                      {...refundFieldProps}
                    />
                  </Col>
                </Col>
                <Col xs={12}>
                  <Popconfirm
                    placement="bottom"
                    title={
                      <React.Fragment>
                        <strong>
                          Please Confirm this manual refund amount
                        </strong>
                        <div>{`Payments Refunded will be set to $${pendingManualPaymentRefund}.`}</div>
                      </React.Fragment>
                    }
                    onConfirm={updateOrder}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      className={styles.processRefundButton}
                      size="medium"
                      disabled={issuingRefund || validatingRefund}
                      loading={issuingRefund}
                    >
                      Manual Refund
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            )}
          />
        </Col>
      </>
    </Drawers.ResDrawer>
  );
};

export default Refund;
