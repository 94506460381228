import * as Yup from "yup";

const bankLoyaltySystem = Yup.object({
  name: Yup.string().required("Name is required"),
  description: Yup.string().required("Description is required"),
  pointMultiplier: Yup.number().required("Point Multiplier is required"),
});

const creditLoyaltySystem = Yup.object({
  name: Yup.string().required("Name is required"),
  description: Yup.string().required("Description is required"),
  conversionGoal: Yup.number().required("Conversion Goal is required"),
  credit: Yup.number().required("Credit is required"),
  pointMultiplier: Yup.number().required("Point Multiplier is required"),
});

const tierLoyaltySystem = Yup.object({
  name: Yup.string().required("Name is required"),
  conversionGoal: Yup.number().required("Conversion Goal is required"),
  pointMultiplier: Yup.number().required("Point Multiplier is required"),
});

const loyaltyTrigger = Yup.object({
  name: Yup.string().required("Name is required"),
  loyaltySystem: Yup.string().required("Loyalty System is required"),
  eventType: Yup.string().required("Event Type is required"),
});

const schema = Yup.object().shape({
  patron: Yup.string().matches(/^[a-f\d]{24}$/),
  value: Yup.number()
    .moreThan(0, "You must enter a positive number")
    .typeError("You have not specified a value")
    .required("You have not specified a value"),

  type: Yup.string()
    .oneOf([
      "coupon",
      "order",
      "credit",
      "recredit",
      "refund",
      "redeemed",
      "spent",
      "spent-loyalty",
      "voided",
    ])
    .required("You have not selected a loyalty type"),
});

export default {
  Schema: schema,
  BankLoyaltySystem: bankLoyaltySystem,
  CreditLoyaltySystem: creditLoyaltySystem,
  TierLoyaltySystem: tierLoyaltySystem,
  LoyaltyTrigger: loyaltyTrigger,
};
